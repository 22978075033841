export const initialFilterValues = {
  q: 'main dish',
  mealType: [],
  diet: [],
  health: [],
  dishType: [],
  cuisineType: [],
  time: [0, 30],
  ingr: [0, 10],
  tag: [],
  excluded: [],
  calories: [0, 2500],
  FAT: [0, 150],
  FASAT: [0, 10],
  FAPU: [0, 10],
  FAMS: [0, 10],
  CHOCDF: [0, 20],
  FIBTG: [0, 20],
  SUGAR: [0, 20],
  PROCNT: [0, 20],
  VITA_RAE: [0, 2500],
  VITC: [0, 75],
  VITD: [0, 100],
  TOCPHA: [0, 5],
  VITK1: [0, 60],
  THIA: [0, 1],
  RIBF: [0, 1],
  NIA: [0, 5],
  VITB6A: [0, 1],
  FOLFD: [0, 200],
  VITB12: [0, 3],
  NA: [0, 250],
  K: [0, 1000],
  CA: [0, 300],
  FE: [0, 5],
  P: [0, 40],
  MG: [0, 150],
  ZN: [0, 5],
  Selenium: [0, 30]
}
export const defaultMaxFilterValues = {
  time: 60,
  ingr: 15,
  calories: 3000,
  FAT: 150,
  FASAT: 10,
  FAPU: 10,
  FAMS: 10,
  CHOCDF: 20,
  FIBTG: 20,
  SUGAR: 20,
  PROCNT: 20,
  VITA_RAE: 2500,
  VITC: 75,
  VITD: 100,
  TOCPHA: 5,
  VITK1: 60,
  THIA: 1,
  RIBF: 1,
  NIA: 5,
  VITB6A: 1,
  FOLFD: 200,
  VITB12: 3,
  NA: 250,
  K: 1000,
  CA: 300,
  FE: 5,
  P: 40,
  MG: 150,
  ZN: 5,
  Selenium: 30
}

export const dietSelectionList = [
  { label: 'Balanced', value: 'balanced' },
  { label: 'High-Fiber', value: 'high-fiber' },
  { label: 'High-Protein', value: 'high-protein' },
  { label: 'Low-Carb', value: 'low-carb' },
  { label: 'Low-Fat', value: 'low-fat' },
  { label: 'Low-Sodium', value: 'low-sodium' }
]

export const healthSelectionList = [
  { label: 'Alcohol-Cocktail', value: 'alcohol-cocktail' },
  { label: 'Alcohol-Free', value: 'alcohol-free' },
  { label: 'Celery-Free', value: 'celery-free' },
  { label: 'Crustcean-Free', value: 'crustacean-free' },
  { label: 'Dairy-Free', value: 'dairy-free' },
  { label: 'DASH', value: 'DASH' },
  { label: 'Egg-Free', value: 'egg-free' },
  { label: 'Fish-Free', value: 'fish-free' },
  { label: 'FODMAP-Free', value: 'fodmap-free' },
  { label: 'Gluten-Free', value: 'gluten-free' },
  { label: 'Immuno-Supportive', value: 'immuno-supportive' },
  { label: 'Keto-Friendly', value: 'keto-friendly' },
  { label: 'Kidney-Friendly', value: 'kidney-friendly' },
  { label: 'Kosher', value: 'kosher' },
  { label: 'Low Potassium', value: 'low-potassium' },
  { label: 'Low Sugar', value: 'low-sugar' },
  { label: 'Lupine-Free', value: 'lupine-free' },
  { label: 'Mediterranean', value: 'Mediterranean' },
  { label: 'Mollusk-Free', value: 'mollusk-free' },
  { label: 'Mustard-Free', value: 'mustard-free' },
  { label: 'No oil added', value: 'No-oil-added' },
  { label: 'Paleo', value: 'paleo' },
  { label: 'Peanut-Free', value: 'peanut-free' },
  { label: 'Pescatarian', value: 'pecatarian' },
  { label: 'Pork-Free', value: 'pork-free' },
  { label: 'Red-Meat-Free', value: 'red-meat-free' },
  { label: 'Sesame-Free', value: 'sesame-free' },
  { label: 'Shellfish-Free', value: 'shellfish-free' },
  { label: 'Soy-Free', value: 'soy-free' },
  { label: 'Sugar-Conscious', value: 'sugar-conscious' },
  { label: 'Sulfite-Free', value: 'sulfite-free' },
  { label: 'Tree-Nut-Free', value: 'tree-nut-free' },
  { label: 'Vegan', value: 'vegan' },
  { label: 'Vegetarian', value: 'vegetarian' },
  { label: 'Wheat-Free', value: 'wheat-free' }
]

export const dishSelectionList = [
  { label: 'Alcohol Cocktail', value: 'alcohol cocktail' },
  { label: 'Biscuits and cookies', value: 'biscuits and cookies' },
  { label: 'Bread', value: 'bread' },
  { label: 'Cereals', value: 'cereals' },
  { label: 'Condiments and sauces', value: 'condiments and sauces' },
  { label: 'Desserts', value: 'desserts' },
  { label: 'Drinks', value: 'drinks' },
  { label: 'Egg', value: 'egg' },
  { label: 'Ice Cream And Custard', value: 'ice cream and custard' },
  { label: 'Main Course', value: 'main course' },
  { label: 'Pancake', value: 'pancake' },
  { label: 'Pasta', value: 'pasta' },
  { label: 'Pastry', value: 'pastry' },
  { label: 'Pies And Tarts', value: 'pies and tarts' },
  { label: 'Pizza', value: 'pizza' },
  { label: 'Preps', value: 'preps' },
  { label: 'Preserve', value: 'preserve' },
  { label: 'Salad', value: 'salad' },
  { label: 'Sandwiches', value: 'sandwiches' },
  { label: 'Seafood', value: 'seafood' },
  { label: 'Side Dish', value: 'side dish' },
  { label: 'Soup', value: 'soup' },
  { label: 'Special Occasions', value: 'special occasions' },
  { label: 'Starter', value: 'starter' },
  { label: 'Sweets', value: 'sweets' }
]

export const cuisineSelectionList = [
  { label: 'American', value: 'american' },
  { label: 'Asian', value: 'asian' },
  { label: 'British', value: 'british' },
  { label: 'Caribbean', value: 'caribbean' },
  { label: 'Central Europe', value: 'central europe' },
  { label: 'Chinese', value: 'chinese' },
  { label: 'Eastern Europe', value: 'eastern europe' },
  { label: 'French', value: 'french' },
  { label: 'Greek', value: 'greek' },
  { label: 'Indian', value: 'indian' },
  { label: 'Italian', value: 'italian' },
  { label: 'Japanese', value: 'japanese' },
  { label: 'Korean', value: 'korean' },
  { label: 'Kosher', value: 'kosher' },
  { label: 'Mediterranean', value: 'mediterranean' },
  { label: 'Mexican', value: 'mexican' },
  { label: 'Middle Eastern', value: 'middle eastern' },
  { label: 'Nordic', value: 'nordic' },
  { label: 'South American', value: 'south american' },
  { label: 'South East Asian', value: 'south east asian' },
  { label: 'World', value: 'world' }
]

const iterateObject = function* (obj) {
  for (let k in obj) yield [k, obj[k]]
}

const findValueFromObjectKey = (objectLists, searchKey, searchValue) => {
  var result = ''
  objectLists.map((objectList) => {
    if (objectList[searchKey] === searchValue) {
      result = objectList['value']
    }
  })
  return result
}

export function createFilterRecipeString(filterRecipe) {
  //console.log("validationError")
  var searchParamObject = ''
  var multiSelectKeyField = ['mealType', 'diet', 'health', 'dishType', 'cuisineType']
  for (var [k, v] of iterateObject(filterRecipe)) {
    //console.log("key = " + k );
    //console.log(typeof(v));

    if (multiSelectKeyField.includes(k)) {
      for (var i = 0; i < v.length; i++) {
        var selectedDropdownValue = v[i]
        switch (k) {
          case 'diet':
            selectedDropdownValue = findValueFromObjectKey(dietSelectionList, 'label', selectedDropdownValue)
            break
          case 'health':
            selectedDropdownValue = findValueFromObjectKey(healthSelectionList, 'label', selectedDropdownValue)
            break
          case 'dishType':
            selectedDropdownValue = findValueFromObjectKey(dishSelectionList, 'label', selectedDropdownValue)
            break
          case 'cuisineType':
            selectedDropdownValue = findValueFromObjectKey(cuisineSelectionList, 'label', selectedDropdownValue)
            break
        }
        searchParamObject += k + '=' + encodeURIComponent(selectedDropdownValue) + '&'
      }
    } else {
      var sliderFilterKeys = Object.keys(defaultMaxFilterValues)
      if (sliderFilterKeys.includes(k)) {
        if (v[1] === defaultMaxFilterValues[k]) {
          v = v[0] + '+'
        }
      }

      switch (k) {
        default:
          if (typeof v === 'object') {
            if (v.length > 0) {
              searchParamObject += encodeURIComponent(k) + '=' + encodeURIComponent(v[0]) + '-' + encodeURIComponent(v[1]) + '&'
            }
          } else {
            searchParamObject += encodeURIComponent(k) + '=' + encodeURIComponent(v) + '&'
          }
          break
      }
    }
  }
  return searchParamObject
  //console.log("searchParamObject");
  //console.log(searchParamObject);
}
