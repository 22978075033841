const NutrientContainDetails = {
  'CHOCDF.net': { colorCode: 'light-salmon', value: '300', label: 'Carbohydrates (net)' },
  CHOCDF: { label: 'Carbs' },
  CHOLE: { colorCode: 'lavender', value: '300', label: 'Cholesterol' },
  FAT: { colorCode: 'dark-pink', value: '78', label: 'Fat' },
  FIBTG: { colorCode: 'blue-green', value: '28', label: 'Fiber' },
  FAMS: { colorCode: 'powder-blue', value: '38', label: 'Monounsaturated' },
  FAPU: { colorCode: 'orange-red-1', value: '16', label: 'Polyunsaturated' },
  PROCNT: { colorCode: 'aqua', value: '50', label: 'Protein' },
  FASAT: { colorCode: 'moderate-cyan', value: '20', label: 'Saturated' },
  SUGAR: { colorCode: 'light-blue', value: '50', label: 'Sugars' },
  FATRN: { colorCode: 'light-yellow', value: '2.2', label: 'Trans' },
  CA: { colorCode: 'brown', value: '1300', label: 'Calcium' },
  FE: { colorCode: 'navy-blue', value: '18', label: 'Iron' },
  MG: { colorCode: 'sky-blue', value: '420', label: 'Magnesium' },
  P: { colorCode: 'sliver', value: '1250', label: 'Phosphorus' },
  K: { colorCode: 'neon-light', value: '4700', label: 'Potassium' },
  NA: { colorCode: 'nasturtium', value: '2300', label: 'Sodium' },
  ZN: { colorCode: 'cadet-blue', value: '11', label: 'Zinc' },
  FOLDFE: { colorCode: 'yellow-green', value: '400', label: 'Folate equivalent (total)' },
  NIA: { colorCode: 'hot-pink', value: '16', label: 'Niacin (B3)' },
  RIBF: { colorCode: 'steel-blue', value: '1.3', label: 'Riboflavin (B2)' },
  THIA: { colorCode: 'rosy-brown', value: '1.2', label: 'Thiamin (B1)' },
  VITA_RAE: { colorCode: 'lime-blue', value: '900', label: 'Vitamin A' },
  VITB12: { colorCode: 'dark-orange', value: '2.4', label: 'Vitamin B12' },
  VITB6A: { colorCode: 'firebrick', value: '1.7', label: 'Vitamin B6' },
  VITC: { colorCode: 'teal', value: '90', label: 'Vitamin C' },
  VITD: { colorCode: 'light-khaki', value: '20', label: 'Vitamin D' },
  TOCPHA: { colorCode: 'magenta', value: '15', label: 'Vitamin E' },
  VITK1: { colorCode: 'yellow-1', value: '120', label: 'Vitamin K' },
  ENERC_KCAL: { colorCode: 'gold-1', value: '2000', label: 'Energy' },
  FOLFD: { label: 'Folate (food)' }
}

export default NutrientContainDetails
