import React, { Fragment, useState } from 'react'
import { Button, Card, Col, Form, Modal, Row } from 'react-bootstrap'
import Select from 'react-select'
import './Grocery.css'

const categories = [
  {
    title: 'Fruits',
    items: [{ id: 'lemon', title: 'Lemon', quantity: '3', unit: '1/2' }]
  },
  {
    title: 'Vegetables',
    items: [
      { id: 'carrot', title: 'Carrot', quantity: '3', unit: '' },
      { id: 'celery', title: 'Celery', quantity: '3', unit: 'Sta' },
      { id: 'cherry-tomatoes', title: 'Cherry Tomatoes', quantity: '2', unit: 'Cup' },
      { id: 'cucumber', title: 'Cucumber', quantity: '', unit: '1/2' }
    ]
  },
  {
    title: 'Bread, Fish, Meat & Cheese'
  },
  {
    title: 'Breakfast'
  },
  {
    title: 'Boxed & Canned'
  },
  {
    title: 'Condiments & Oils'
  },
  {
    title: 'Seeds, Nuts & Spices'
  },
  {
    title: 'Baking'
  },
  {
    title: 'Cold'
  },
  {
    title: 'Frozen'
  },
  {
    title: 'Others'
  }
]

const GroceryModal = ({ open, closeModal }) => {
  const [groceryStoreModalPopUp, setGroceryStoreModalPopUp] = useState(false)
  const [groceryAddNewItemModalPopUp, setGroceryAddNewItemModalPopUp] = useState(false)

  return (
    <Fragment>
      <Modal centered fullscreen show={open} onHide={closeModal} className="grocery-modal" tabindex="-1">
        <Modal.Header closeButton>
          <Modal.Title>Groceries</Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex flex-column flex-grow-1">
          <div className="flex gap-3">
            <Button className="btn-export">Export</Button>
            <Button className="btn-order-groceries" onClick={() => setGroceryStoreModalPopUp(true)}>
              Order Groceries
            </Button>
            <Button className="btn-add-items ms-auto" onClick={() => setGroceryAddNewItemModalPopUp(true)}>
              Add Item
            </Button>
          </div>
          <Row className="g-4 mt-1 mb-4">
            {categories.map((category) => (
              <Col md={3}>
                <Card>
                  <Card.Header>{category.title}</Card.Header>
                  <Card.Body className="items">
                    {category?.items?.map((item) => (
                      <Form.Label className="d-flex align-items-center gap-3 item">
                        <Form.Check type="checkbox" />
                        <span className="quantity-unit">
                          {item.quantity} {item.unit}
                        </span>
                        <span className="title">{item.title}</span>
                      </Form.Label>
                    ))}
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
          <Button className="mt-auto btn-remove-items">Remove Items</Button>
        </Modal.Body>
      </Modal>
      <Modal centered show={groceryStoreModalPopUp} onHide={() => setGroceryStoreModalPopUp(false)} className="grocery-store-modal">
        <Modal.Header closeButton className="text-center">
          <Modal.Title>Select Your Grocery Store</Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex flex-column gap-4 p-4">
          <Button>Walmart</Button>
          <Button>Wegmans</Button>
          <Button>Kroger</Button>
          <Button>Amazon Fresh</Button>
        </Modal.Body>
      </Modal>
      <Modal centered show={groceryAddNewItemModalPopUp} onHide={() => setGroceryAddNewItemModalPopUp(false)} className="grocery-add-new-item-modal">
        <Modal.Header closeButton className="text-center">
          <Modal.Title>Add new items</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-4">
          <Row className="mb-3">
            <Form.Group as={Col} controlId="quantity">
              <Form.Label>Quantity</Form.Label>
              <Form.Control type="text" placeholder="Enter quantity" />
            </Form.Group>

            <Form.Group as={Col} controlId="units">
              <Form.Label>Unit of measures</Form.Label>
              <Form.Control type="text" placeholder="Enter units" />
            </Form.Group>
          </Row>
          <Form.Group as={Col} controlId="item-name" className="mb-3">
            <Form.Label>Name of item</Form.Label>
            <Form.Control type="text" placeholder="Enter name of item" />
          </Form.Group>
          <Form.Group as={Col} controlId="category" className="mb-4">
            <Form.Label>Category</Form.Label>
            <Select
              id="category"
              placeholder="Enter Category"
              options={[
                {
                  label: 'Baking',
                  value: 'Baking'
                },
                {
                  label: 'Boxed & Canned',
                  value: 'Boxed & Canned'
                },
                {
                  label: 'Bread, Fish, Meat & Cheese',
                  value: 'Bread, Fish, Meat & Cheese'
                },
                {
                  label: 'Breakfast',
                  value: 'Breakfast'
                },
                {
                  label: 'Cold',
                  value: 'Cold'
                },
                {
                  label: 'Condiments & Oils',
                  value: 'Condiments & Oils'
                },
                {
                  label: 'Frozen',
                  value: 'Frozen'
                },
                {
                  label: 'Fruits',
                  value: 'Fruits'
                },
                {
                  label: 'Seeds, Nuts & Spices',
                  value: 'Seeds, Nuts & Spices'
                },
                {
                  label: 'Vegetables',
                  value: 'Vegetables'
                },
                {
                  label: 'Other',
                  value: 'Other'
                }
              ]}
            />
          </Form.Group>
          <div className="d-flex gap-3">
            <Button variant="outline-primary" type="submit" className="w-50 cancel">
              Cancel
            </Button>
            <Button variant="primary" type="submit" className="w-50 add-item">
              Add Item
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </Fragment>
  )
}

export default GroceryModal
