import React, {useState, useEffect, useCallback} from 'react';
import { Col, Modal, ModalHeader, Tabs, Tab, Row, Button, ButtonGroup, DropdownButton, Dropdown} from 'react-bootstrap';
import { Link, useParams, useNavigate } from 'react-router-dom';
import uploadIcon from '../../src/assets/images/upload-icon.png';
import plusIcon from '../../src/assets/images/plus-icon.png';
import ConfirmModal from "../components/common/ConfirmModal";
import {toast, ToastContainer} from 'react-toastify'
import { TimePicker } from 'antd';
import commonNutrientContainDetails from "../components/NutrientContainDetails";
import { useForm } from 'react-hook-form';
import { useAuth } from '../context/AuthProvider';
import { TailSpin } from 'react-loader-spinner';
import ListingCardHorizonal from '../components/ListingCardHorizonal';
import moment from 'moment';

export default function AddRecipe() {
    const { myRecipeId } = useParams();
    
    const { user, isAuthenticated } = useAuth()
    const navigate = useNavigate()

    const [showIngredientModal, setShowIngredientModal] = useState(false);
    const [showDirectionModal, setShowDirectionModal] = useState(false);
    const [showNoteModal, setShowNoteModal] = useState(false);
    const [editRecipeId, setEditRecipeId] = useState(myRecipeId || null);
    const [loading, setLoading] = useState(true);
    const [editRecordDetails, setEditRecordDetails] = useState(false);
    const [showApiIngredientDropDown, setShowApiIngredientDropDown] = useState(false);

    // if page Edit get edit details
    useEffect(() => {
        if(editRecipeId != null && editRecipeId != '' && editRecordDetails != true){
            fetEditRecipeDetails();
            setIngredientNutrientsDetails(ingredientNutrientsDetailsArray);
        } else {
            setLoading(false);
        }

        if(document.activeElement == document.getElementById('name-of-ingredient')){
            setShowApiIngredientDropDown(true)
        } else {
            setShowApiIngredientDropDown(false)
        }
    });


    const [dbEditSelectedImage, setDbEditSelectedImage] = useState(null);
    const fetEditRecipeDetails = async () => {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/admin/getRecipeInfo/${editRecipeId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => res.json()).then(data => { return data })

        if (response.status) {
            const colletEditResposeData = response.data || null;
            if(colletEditResposeData != null){
                setEditRecordDetails(true);
                setDbEditSelectedImage(colletEditResposeData?.image)
                setRecepeSelectedImages(colletEditResposeData?.images);
                resetRecipe(colletEditResposeData?.actualFormData);
                setRecipeInfo(colletEditResposeData?.actualFormData);
                setMasterTagDetails(colletEditResposeData?.tags);
                setMasterIngredientDetails(colletEditResposeData?.actualIngredient);
                setMasterDirectionDetails(colletEditResposeData?.directions);
                setMasterNoteDetails(colletEditResposeData?.notes);
                setMasterMealType(colletEditResposeData?.mealType);

                setDbMasterIngredientDetails(colletEditResposeData?.actualIngredientWithId);
                setDbMasterIngredientDietLabelsDetails(colletEditResposeData?.dietLabels);
                setDbMasterIngredientLinesDetails(colletEditResposeData?.actualIngredientLines);
                setDbMasterIngredientHealthLabelsDetails(colletEditResposeData?.healthLabels);
                setDbMasterIngredientCautionDetails(colletEditResposeData?.cautions);
                
                setLoading(false);
            } else {
                console.log("some thin serror", colletEditResposeData)
                setLoading(false);    
                //navigate('/my-recipe');
            }
        } else {
            console.log("error", response.message)
            // toast.error(response.message)
            setLoading(false);
            navigate('/my-recipe');
        }
    }

    // Single image preview
    const [recipeSelectedImages, setRecepeSelectedImages] = useState([]);
    const [masterRecipeSelectImage, setMasterRecipeSelectImage] = useState(null);
    const recipeImagePreview = (e) => {
        let recipeImage = [];
        recipeImage.push((e.target.files));
        let reader = new FileReader();
        let file = e.target.files[0];        

        if(file.name.match(/.(jpg|jpeg|png|gif|svg)$/i)){
            let imageId = "img" + Math.random().toString(16).slice(2);
            reader.onloadend = () => {
                setRecepeSelectedImages(
                    [
                        {
                            id: imageId,
                            //fileData: file,
                            filename: file.name,
                            filetype: file.type,
                            fileimage: reader.result,
                            datetime: file.lastModifiedDate.toLocaleString('en-IN'),
                            filesize: file.size
                        }
                    ]
                );
            }
            if (file) {
                reader.readAsDataURL(file);
            }
            setMasterRecipeSelectImage(file);
        } else {
            toast.error('Please use image format jpg,jpeg,png,gif,svg.')
        }
    }

    useEffect(() => {
        setTimeout(function(){
            //document.getElementById("image-upload").value = "";
       }, 500);
    } , [recipeSelectedImages]);

    // Delete Select Image
    const [recipeImageDeleteId, setRecipeImageDeleteId] = useState(null);
    const [showRecipeImageDeleteModal, setShowRecipeImageDeleteModal] = useState(false);

   const deleteImageSelection = (e) => {
        const removeImageValue = e.target.value;
        if(e.target.checked != false){
            setRecipeImageDeleteId(removeImageValue)
        } else {
            setRecipeImageDeleteId(null)
        }
    }

    const deleteRecipeSelectedImages = () => {
        if(recipeImageDeleteId != '' && recipeImageDeleteId != null){
            setShowRecipeImageDeleteModal(true);
        } else {
            toast.error('Please select image.')
        }
    }

    const handleDeleteRecipeSelectedImages = () => {
        if(recipeImageDeleteId != '' && recipeImageDeleteId != null){
            if((dbEditSelectedImage != '' && dbEditSelectedImage != null && dbEditSelectedImage.length > 0 && editRecipeId != null && editRecipeId != '')){
                setDbEditSelectedImage(null);
            } else {
                setRecepeSelectedImages({})
            }
        } else {
            toast.error('Please select image.')   
        }
        setRecipeImageDeleteId(null);
        setShowRecipeImageDeleteModal(false);
    }

    // hide delete confirmbox
    useEffect(() => {
        if(recipeImageDeleteId == null || recipeImageDeleteId == '' ){
            setShowRecipeImageDeleteModal(false);
        }
    } , [recipeImageDeleteId]);

    // Save Recipe images
    /* const saveRecipeSelectedImages = () => {
        console.log('Save Image action');
    } */

    // Fetch data from API
    const [apiIngredientDetails, setApiIngredientDetails] = useState({});
    const [apiNutrientInfoFetch, setApiNutrientInfoFetch] = useState(false);
    const fetchApiIngredientDetails = async (e) => {    
        const searchQuery = e.target.value.trim();

        setApiNutrientInfoFetch(false);

        if(searchQuery.length > 2){
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/admin/getIngredients?q=${searchQuery}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(res => res.json()).then(data => { return data })
    

            if (response.status) {
                setApiIngredientDetails(response.data);
            } else {
                toast.error(response.message)
            }
        } else {
            setApiIngredientDetails({});
        }

        fetchApiNutrientInfo('Nutrients');
    }

    // Fetch nutrients details
    const [apiNutrientInfo, setApiNutrientInfo] = useState(null);
    //const [apiMasterNutrientInfo, setApiMasterNutrientInfo] = useState({});
    const [ingredientNutrientsDetails, setIngredientNutrientsDetails] = useState([]);
    const ingredientNutrientsDetailsArray = ['CHOCDF', 'FAT', 'FIBTG', 'SUGAR', 'PROCNT', 'CHOLE', 'NA', 'VITA_RAE', 'VITC', 'CA'];

    const fetchApiNutrientInfo = async (key) => {
        // && apiNutrientInfoFetch != true
        if(key === 'Nutrients'){
            const nutrientQuantity = ingredientInfo?.quantity.trim();
            const nutrientUnitOfMesure = ingredientInfo?.unit_of_measure.trim(); // tbsp cup
            const nutrient = ingredientInfo?.name_of_ingredient.trim(); // Coconut Aminos Coconut Sugar
    
            if(nutrient.length > 2){
                const response = await fetch(`${process.env.REACT_APP_BASE_URL}/admin/getIngredientNutrient?nutrientQuantity=${nutrientQuantity}&nutrientUnitOfMesure=${nutrientUnitOfMesure}&nutrient=${nutrient}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }).then(res => res.json()).then(data => { return data })
        
                if (response.status) {
                    setApiNutrientInfoFetch(true);
                    setApiNutrientInfo(response.data);
                } else {
                    toast.error(response.message)
                }
            } else {
                setApiNutrientInfo(null);
            }
        } else {
            if(key != 'Nutrients'){
                setApiNutrientInfoFetch(false);
            }
        }
    }

    // get Nutrient Color Code
    const getNutrientColorCode = (nutrientKey) => {
    	var colorCode = "light";
    	if( commonNutrientContainDetails.hasOwnProperty(nutrientKey) !== false ){
    		colorCode = commonNutrientContainDetails[nutrientKey]['colorCode'];
            colorCode = (typeof colorCode !== 'undefined') ? colorCode : 'light';
    	}
    	return colorCode;
    }

    const DefaultIngredientUnitDetails = [
        '(none)',
        'bulb',
        'can',
        'cup',
        'ear',
        'fl oz',
        'gallon',
        'gram',
        'head',
        'kilogram',
        'lb',
        'leave',
        'liter',
        'milligram',
        'milliliter',
        'oz',
        'piece',
        'pint',
        'quart',
        'serving',
        'slice',
        'stalk',
        'tbsp',
        'tsp',
    ];

    const DefaultIngredientCategoryDetails = [
        'Fruits',
        'Vegetables',
        'Bread, Fish, Meat & Cheese',
        'Breakfast',
        'Boxed & Canned',
        'Condiments & Oils',
        'Seeds, Nuts & Spices',
        'Baking',
        'Cold',
        'Frozen',
        'Other',     
    ];

    // get Nutrient Label
    const getNutrientLabel = (nutrientKey) => {
    	var nutrientLabel = "";
    	if( commonNutrientContainDetails.hasOwnProperty(nutrientKey) !== false ){
    		nutrientLabel = commonNutrientContainDetails[nutrientKey]['label'];

            switch (nutrientKey) {
                case 'ENERC_KCAL':
                    nutrientLabel = 'Calories';
                  break;
                case 'CHOCDF':
                    nutrientLabel = 'Carbohydrates';
                  break;
                case 'SUGAR':
                    nutrientLabel = 'Sugar';
                  break;
                case 6:
                    nutrientLabel = (typeof nutrientLabel !== 'undefined') ? nutrientLabel : '';
              }
    	}
    	return nutrientLabel;
    }

    // change nutrient base on data
    useEffect(() => {
        setIngredientNutrientsDetails(ingredientNutrientsDetailsArray);
    } , [apiNutrientInfo]);

    // Only allow number
    const isNumberKey = (e) => {
        var charCode = (e.which) ? e.which : e.keyCode;
        var validKeyOrNot = false;
        
        if(charCode == 13){
            return true;
        }

        if (charCode == 46) {
            if (e.target.value.indexOf('.') === -1) {
                validKeyOrNot = true;
            }
        } else {
            if (charCode > 31 && (charCode < 48 || charCode > 57)){
                validKeyOrNot = false;
            } else {
                validKeyOrNot = true;
            }
        }
        
        if(validKeyOrNot != true){
            e.stopPropagation();
            e.preventDefault(); 
        }
        return true;
    }

    const { register : registerIngredient, handleSubmit : handleSubmitIngredient, formState: { errors : ingredientErrors }, reset : resetIngredient} = useForm({mode: "onChange"});
    
    const defaultIngredientInfo = {
    	    "name_of_ingredient": "",
    	    "nutrition_form": "",
    	    "quantity": "",
    	    "unit_of_measure": "",
    	    "category": "",
    	    "preparation_notes": "",
    }

    const [ masterIngredientDetails , setMasterIngredientDetails ] = useState({});
    const [ ingredientInfo , setIngredientInfo ] = useState(defaultIngredientInfo);
    const [ ingredientRecordId , setIngredientRecordId ] = useState(null);
    const [ dbMasterIngredientDetails , setDbMasterIngredientDetails ] = useState({});
    const [ dbMasterIngredientDietLabelsDetails , setDbMasterIngredientDietLabelsDetails ] = useState({});
    const [ dbMasterIngredientLinesDetails , setDbMasterIngredientLinesDetails ] = useState({});
    const [ dbMasterIngredientHealthLabelsDetails , setDbMasterIngredientHealthLabelsDetails ] = useState({});
    const [ dbMasterIngredientCautionDetails , setDbMasterIngredientCautionDetails ] = useState({});
    
    const onIngredientFormSubmit = (data) => {
        console.log("data", data, ingredientInfo, )
        let recordId = "ing" + Math.random().toString(16).slice(2);
        
        const collectApiNutrientInfo = ( typeof apiNutrientInfo !== 'undefined' && apiNutrientInfo != null && Object.keys(apiNutrientInfo).length > 0 ? apiNutrientInfo : {} );

        if(apiNutrientInfo != null){
            data = { ...ingredientInfo, apiNutrientInfo : collectApiNutrientInfo }
        } else {
            /* console.log('no data found');
            fetchApiNutrientInfo('Nutrients'); */
        }

        const ingredientTextLine = ((data.hasOwnProperty('name_of_ingredient') && data.name_of_ingredient != '' && data.name_of_ingredient != null) ? ( (data.hasOwnProperty('quantity') && data.quantity != '' && data.quantity != null ? data.quantity + ' ' : '') + (data.hasOwnProperty('unit_of_measure') && data.unit_of_measure != '' && data.unit_of_measure != null ? data.unit_of_measure + ' ' : '') ) + data.name_of_ingredient : '');

        const dbData = {
            text: ingredientTextLine,
            quantity: data?.quantity,
            measure: data?.unit_of_measure,
            food: data?.name_of_ingredient,
            weight: '',
            foodCategory: data?.category,
            foodId: '',
            image: '',
            apiNutrientInfo: collectApiNutrientInfo
        }        

        if( ingredientRecordId ) {
        	setMasterIngredientDetails({...masterIngredientDetails, [ingredientRecordId] : data});
            setDbMasterIngredientDetails({...dbMasterIngredientDetails, [ingredientRecordId] : dbData});
            setDbMasterIngredientDietLabelsDetails({...dbMasterIngredientDietLabelsDetails, [ingredientRecordId] : collectApiNutrientInfo?.dietLabels});
            setDbMasterIngredientLinesDetails({...dbMasterIngredientLinesDetails, [ingredientRecordId] : ingredientTextLine});
            setDbMasterIngredientHealthLabelsDetails({...dbMasterIngredientHealthLabelsDetails, [ingredientRecordId] : collectApiNutrientInfo?.healthLabels});
            setDbMasterIngredientCautionDetails({...dbMasterIngredientCautionDetails, [ingredientRecordId] : collectApiNutrientInfo?.cautions});
        } else {
        	setMasterIngredientDetails({...masterIngredientDetails, [recordId] : data});
            setDbMasterIngredientDetails({...dbMasterIngredientDetails, [recordId] : dbData});
            setDbMasterIngredientDietLabelsDetails({...dbMasterIngredientDietLabelsDetails, [recordId] : collectApiNutrientInfo?.dietLabels});
            setDbMasterIngredientLinesDetails({...dbMasterIngredientLinesDetails, [recordId] : ingredientTextLine});
            setDbMasterIngredientHealthLabelsDetails({...dbMasterIngredientHealthLabelsDetails, [recordId] : collectApiNutrientInfo?.healthLabels});
            setDbMasterIngredientCautionDetails({...dbMasterIngredientCautionDetails, [recordId] : collectApiNutrientInfo?.cautions});
        }
        setSelectIngredientFromApi(false);
        setShowIngredientModal(false);
    }
    
    const handleIngredientInputFields = (e) => {
        fetchApiNutrientInfo('Nutrients');
        setIngredientInfo({...ingredientInfo , [e.target.name] : e.target.value});
        if(e.target.name === 'name_of_ingredient'){
            setSelectIngredientFromApi(false);
            setIngredientInfo( prev => {
                return { ...prev, 'nutrition_form' : 'Unavailable'}
            });
        }
    }
    
    const handleOpenIngredientModal = (recordId = null) => {
    	setIngredientRecordId(recordId);
        setIngredientNutrientsDetails([]);
        setApiNutrientInfo(null);
        setApiNutrientInfoFetch(false);
        fetchApiNutrientInfo('Nutrients');
    	setShowIngredientModal(true);
    }
    
    useEffect(() => {
    	if( ingredientRecordId ) {
    		resetIngredient(masterIngredientDetails[ingredientRecordId]);
    		setIngredientInfo(masterIngredientDetails[ingredientRecordId]);
            setApiNutrientInfoFetch( ( masterIngredientDetails[ingredientRecordId]?.nutrition_form != 'Unavailable' ? true : false ) );
            setSelectIngredientFromApi ( ( masterIngredientDetails[ingredientRecordId]?.nutrition_form != 'Unavailable' ? true : false ) ) 
    	} else {
    		resetIngredient(defaultIngredientInfo);
            setIngredientInfo(defaultIngredientInfo);
    	}
        setApiIngredientDetails({})
    } , [ingredientRecordId, showIngredientModal]);
    
    const handleCloseIngredientModal = () => {    	
    	setIngredientRecordId(null)
        setSelectIngredientFromApi(false)
    	setShowIngredientModal(false)
    }
    
    // Ingredient Delete Handle
    const [showIngredientDeleteModal, setShowIngredientDeleteModal] = useState(false);
    const [ingredientDeleteId, setIngredientDeleteId] = useState(null);
    const handleDeleteIngredient = () => {
        if(ingredientDeleteId != '' && ingredientDeleteId != null){
            //const resultIngredientDetails = ingredientDetails.filter((data) => data.id != ingredientDeleteId);
            //setIngredientDetails(resultIngredientDetails);
            delete masterIngredientDetails[ingredientDeleteId];
        } else {
            toast.error('Please select image.')   
        }
        setIngredientDeleteId(null);
        setShowIngredientDeleteModal(false);
    }
    
    const deleteSelectedIngredient = (recordId) => {
        if(recordId != '' && recordId != null){
            setShowIngredientDeleteModal(true);
            setIngredientDeleteId(recordId);
        } else {
            toast.error('Please select image.')
        }
    }

    // hide delete confirmbox
    useEffect(() => {
        if(ingredientDeleteId == null){
            setShowIngredientDeleteModal(false);
        }
    } , [ingredientDeleteId]);

    // Calculate final nutriaion details
    const [apiMasterNutrientDetails, setApiMasterNutrientDetails] = useState({});
    const [ dbMasterTotalNutrientsDetails , setDbMasterTotalNutrientsDetails ] = useState({});
    const [ dbMasterTotalDailyDetails , setDbMasterTotalDailyDetails ] = useState({});
    
    const calculateFinalNutrientDetails = (collectMasterIngredientDetails = {}) => {
        let calculateRowData = {};
        let collectApiTotalNutrientInfo = {}
        let collectApiTotalDailyInfo = {}
        
        if(Object.keys(collectMasterIngredientDetails).length > 0){
            Object.entries(collectMasterIngredientDetails).map(([ collectMasterIngredientId, collectMasterIngredientDetail ], i) => {
                const getIngredientApiNutrientDetails = collectMasterIngredientDetail?.apiNutrientInfo;

                calculateRowData = { ...calculateRowData, calories : ( parseFloat((calculateRowData?.calories || 0)) + (getIngredientApiNutrientDetails?.calories != '' && getIngredientApiNutrientDetails?.calories != null && (typeof getIngredientApiNutrientDetails != 'undefined' && getIngredientApiNutrientDetails != null) ? parseFloat(getIngredientApiNutrientDetails?.calories) : 0) ) };

                if(ingredientNutrientsDetails.length > 0 && (typeof getIngredientApiNutrientDetails != 'undefined' && getIngredientApiNutrientDetails != null)) {
                    ingredientNutrientsDetails.map((ingredientNutrientsDetail, index) => {                    
                        let getApiTotalNutrientQuantity = 0;
                        let getApiTotalNutrientUnit = '';
                        let getApiTotalDailyQuantity = 0;

                        const getApiTotalNutrientDetails = getIngredientApiNutrientDetails?.totalNutrients;
                        if(typeof getApiTotalNutrientDetails != 'undefined'){                            
                            if(getApiTotalNutrientDetails.hasOwnProperty(ingredientNutrientsDetail) != false){
                                let getApiTotalNutrientInfo = getApiTotalNutrientDetails[ingredientNutrientsDetail];  
                                getApiTotalNutrientQuantity = (getApiTotalNutrientInfo != '' && getApiTotalNutrientInfo != null ? getApiTotalNutrientInfo.quantity : 0)
                                getApiTotalNutrientUnit = (getApiTotalNutrientInfo != '' && getApiTotalNutrientInfo != null ? getApiTotalNutrientInfo.unit : '')
                            }
                            
                            const getApiTotalDailyDetails = getIngredientApiNutrientDetails?.totalDaily;
                            if(getApiTotalDailyDetails.hasOwnProperty(ingredientNutrientsDetail) != false){
                                let getApiTotalDailyInfo = getApiTotalDailyDetails[ingredientNutrientsDetail];
                                getApiTotalDailyQuantity = (getApiTotalDailyInfo != '' && getApiTotalDailyInfo != null ? getApiTotalDailyInfo.quantity : 0)
                            }
                        }
                        
                        calculateRowData = { ...calculateRowData, [ingredientNutrientsDetail] : {
                            quantity : ( parseFloat((calculateRowData[ingredientNutrientsDetail]?.quantity || 0)) + parseFloat(getApiTotalNutrientQuantity || 0) ),
                            perentage : ( parseFloat((calculateRowData[ingredientNutrientsDetail]?.perentage || 0)) + parseFloat(getApiTotalDailyQuantity || 0) ),
                            unit : getApiTotalNutrientUnit
                        } };
                        
                    })
                }

                // Collect final all calculation data
                if(typeof getIngredientApiNutrientDetails != 'undefined' && getIngredientApiNutrientDetails != null){
                    let collectTotalNutrientsDetails = getIngredientApiNutrientDetails?.totalNutrients;
                    let collectTotalDailyDetails = getIngredientApiNutrientDetails?.totalDaily;                    
                    
                    if(collectTotalNutrientsDetails != '' && collectTotalNutrientsDetails != null){
                        Object.keys(collectTotalNutrientsDetails).forEach(function(collectTotalNutrientsKey) {

                            collectApiTotalNutrientInfo = {
                                ...collectApiTotalNutrientInfo, 
                                [collectTotalNutrientsKey] : {
                                    ...collectApiTotalNutrientInfo[collectTotalNutrientsKey],
                                    label: (collectTotalNutrientsDetails[collectTotalNutrientsKey]?.label || ''),
                                    quantity: ( (collectApiTotalNutrientInfo[collectTotalNutrientsKey]?.quantity || 0) + (collectTotalNutrientsDetails[collectTotalNutrientsKey]?.quantity || 0) ),
                                    unit: (collectTotalNutrientsDetails[collectTotalNutrientsKey]?.unit || '') 
                                }
                            }
                        });					
                    }
                    
                    if(collectTotalDailyDetails != '' && collectTotalDailyDetails != null){
                        Object.keys(collectTotalDailyDetails).forEach(function(collectTotalDailyKey) {
                            collectApiTotalDailyInfo = {
                                ...collectApiTotalDailyInfo, 
                                [collectTotalDailyKey] : {
                                    ...collectApiTotalDailyInfo[collectTotalDailyKey],
                                    label: (collectTotalDailyDetails[collectTotalDailyKey]?.label || ''),
                                    quantity: ( (collectApiTotalDailyInfo[collectTotalDailyKey]?.quantity || 0) + (collectTotalDailyDetails[collectTotalDailyKey]?.quantity || 0) ),
                                    unit: (collectTotalDailyDetails[collectTotalDailyKey]?.unit || '') 
                                }
                            }
                        });						
                    }
                }
            })
        }
        setApiMasterNutrientDetails(calculateRowData);
        setDbMasterTotalNutrientsDetails(collectApiTotalNutrientInfo);
        setDbMasterTotalDailyDetails(collectApiTotalDailyInfo);
    }

    useEffect(() => {
        calculateFinalNutrientDetails(masterIngredientDetails)
    }, [masterIngredientDetails])

    // directions function start
    const { register : registerDirection, handleSubmit : handleSubmitDirection, formState: { errors : directionErrors }, reset : resetDirection} = useForm({mode: "onBlur"});
    
    const defaultDirectionInfo = {
            "direction": ""
    }

    const onDirectionFormSubmit = (data) => {
        let recordId = "dir" + Math.random().toString(16).slice(2);
        if( directionRecordId ) {
            setMasterDirectionDetails({...masterDirectionDetails, [directionRecordId] : data});
        } else {
            setMasterDirectionDetails({...masterDirectionDetails, [recordId] : data});
        }
        setShowDirectionModal(false);
    }

    const handleDirectionInputFields = (e) => {
        setDirectionInfo({...directionInfo , [e.target.name] : e.target.value});
    }

    const [ masterDirectionDetails , setMasterDirectionDetails ] = useState({});
    const [ directionInfo , setDirectionInfo ] = useState(defaultDirectionInfo);
    const [ directionRecordId , setDirectionRecordId ] = useState(null);

    const handleOpenDirectionModal = (recordId = null) => {
        setDirectionRecordId(recordId)
        setShowDirectionModal(true)
    }

    useEffect(() => {
        if( directionRecordId ) {
            resetDirection(masterDirectionDetails[directionRecordId]);
            setDirectionInfo(masterDirectionDetails[directionRecordId]);
        } else {
            resetDirection(defaultDirectionInfo);
            setDirectionInfo(defaultDirectionInfo);
        }
    } , [directionRecordId, showDirectionModal]);

    const handleCloseDirectionModal = () => {    	
        setDirectionRecordId(null)
        setShowDirectionModal(false)
    }

    // Direction Delete Handle
    const [showDirectionDeleteModal, setShowDirectionDeleteModal] = useState(false);
    const [directionDeleteId, setDirectionDeleteId] = useState(null);
    const handleDeleteDirection = () => {
        if(directionDeleteId != '' && directionDeleteId != null){
            delete masterDirectionDetails[directionDeleteId];
        } else {
            toast.error('Please select image.')   
        }
        setDirectionDeleteId(null);
        setShowDirectionDeleteModal(false);
    }
    
    const deleteSelectedDirection = (recordId) => {
        if(recordId != '' && recordId != null){
            setShowDirectionDeleteModal(true);
            setDirectionDeleteId(recordId);
        } else {
            toast.error('Please select image.')
        }
    }

    // hide delete confirmbox
    useEffect(() => {
        if(directionDeleteId == null){
            setShowDirectionDeleteModal(false);
        }
    } , [directionDeleteId]);

    // note function start
    const { register : registerNote, handleSubmit : handleSubmitNote, formState: { errors : noteErrors }, reset : resetNote} = useForm({mode: "onBlur"});
    
    const defaultNoteInfo = {
            "note": ""
    }

    const onNoteFormSubmit = (data) => {
        let recordId = "dir" + Math.random().toString(16).slice(2);
        if( noteRecordId ) {
            setMasterNoteDetails({...masterNoteDetails, [noteRecordId] : data});
        } else {
            setMasterNoteDetails({...masterNoteDetails, [recordId] : data});
        }
        setShowNoteModal(false);
    }

    const handleNoteInputFields = (e) => {
        setNoteInfo({...noteInfo , [e.target.name] : e.target.value});
    }

    const [ masterNoteDetails , setMasterNoteDetails ] = useState({});
    const [ noteInfo , setNoteInfo ] = useState(defaultNoteInfo);
    const [ noteRecordId , setNoteRecordId ] = useState(null);

    const handleOpenNoteModal = (recordId = null) => {
        setNoteRecordId(recordId)
        setShowNoteModal(true)
    }

    useEffect(() => {
        if( noteRecordId ) {
            resetNote(masterNoteDetails[noteRecordId]);
            setNoteInfo(masterNoteDetails[noteRecordId]);
        } else {
            resetNote(defaultNoteInfo);
            setNoteInfo(defaultNoteInfo);
        }
    } , [noteRecordId, showNoteModal]);

    const handleCloseNoteModal = () => {    	
        setNoteRecordId(null)
        setShowNoteModal(false)
    }

    // Note Delete Handle
    const [showNoteDeleteModal, setShowNoteDeleteModal] = useState(false);
    const [noteDeleteId, setNoteDeleteId] = useState(null);
    const handleDeleteNote = () => {
        if(noteDeleteId != '' && noteDeleteId != null){
            delete masterNoteDetails[noteDeleteId];
        } else {
            toast.error('Please select image.')   
        }
        setNoteDeleteId(null);
        setShowNoteDeleteModal(false);
    }
    
    const deleteSelectedNote = (recordId) => {
        if(recordId != '' && recordId != null){
            setShowNoteDeleteModal(true);
            setNoteDeleteId(recordId);
        } else {
            toast.error('Please select image.')
        }
    }

    // hide delete confirmbox
    useEffect(() => {
        if(noteDeleteId == null){
            setShowNoteDeleteModal(false);
        }
    } , [noteDeleteId]);


    // Final Recipe Details Submit

    const { register : registerRecipe, handleSubmit : handleSubmitRecipe, formState: { errors : recipeErrors }, reset : resetRecipe} = useForm({mode: "onBlur"});

    const defaultRecipeInfo = {
        "title": "",
        "time": "",
        "servings": ""
    }

    const getFinalOutputIngredientObjects = (actionObject = null) => {
        if(actionObject != null && actionObject != '' && Object.keys(actionObject).length > 0){
            return Object.values(actionObject).flat().filter( function( item, index, inputArray ) {
                return inputArray.indexOf(item) == index;
            });
        }
        return false;
    }

    const [ recipeInfo , setRecipeInfo ] = useState(defaultRecipeInfo);

    const onRecipeFormSubmit = async (data) => {
        setLoading(true);

        if(Object.keys(masterIngredientDetails).length === 0){
            toast.error('Please Add At Least One Ingredients.')
            return false;
        }

        const masterRecipeInfo = {};
        masterRecipeInfo.uri = '';
        masterRecipeInfo.label = data?.title;
        masterRecipeInfo.image = (dbEditSelectedImage || '');
        masterRecipeInfo.images = [];
        masterRecipeInfo.source = '';
        masterRecipeInfo.url = '';
        masterRecipeInfo.shareAs = '';
        masterRecipeInfo.yield = data?.servings;
        masterRecipeInfo.dietLabels = getFinalOutputIngredientObjects(dbMasterIngredientDietLabelsDetails);
        masterRecipeInfo.healthLabels = getFinalOutputIngredientObjects(dbMasterIngredientHealthLabelsDetails);
        masterRecipeInfo.cautions = getFinalOutputIngredientObjects(dbMasterIngredientCautionDetails);
        masterRecipeInfo.ingredientLines = Object.values(dbMasterIngredientLinesDetails);
        masterRecipeInfo.ingredients = Object.values(dbMasterIngredientDetails);
        masterRecipeInfo.actualIngredientLines = dbMasterIngredientDetails;
        masterRecipeInfo.actualIngredientWithId = dbMasterIngredientDetails;
        masterRecipeInfo.calories = (apiNutrientInfo?.calories || 0);
        masterRecipeInfo.totalCO2Emissions = '';
        masterRecipeInfo.co2EmissionsClass = '';
        masterRecipeInfo.totalWeight = '';
        masterRecipeInfo.totalTime = recipeInfo?.time;
        masterRecipeInfo.cuisineType = '';
        masterRecipeInfo.mealType = masterMealType;
        masterRecipeInfo.dishType = '';
        masterRecipeInfo.totalNutrients = dbMasterTotalNutrientsDetails;
        masterRecipeInfo.totalDaily = dbMasterTotalDailyDetails;
        masterRecipeInfo.digest = '';
        masterRecipeInfo._links = '';
        masterRecipeInfo.directions = masterDirectionDetails;
        masterRecipeInfo.notes = masterNoteDetails;
        masterRecipeInfo.actualIngredient = masterIngredientDetails;
        masterRecipeInfo.tags = masterTagDetails;
        masterRecipeInfo.actualFormData = recipeInfo;
        
        
        const userData = JSON.parse(localStorage.getItem('userdata'));
        const token = userData.token;
        masterRecipeInfo.userId = userData?._id;
        
        const formData = new FormData();

        if(editRecipeId != null && editRecipeId != '' && window.location.pathname === ('/edit-recipe/' + editRecipeId)){            
            masterRecipeInfo._id = editRecipeId;
            formData.append('recipe', JSON.stringify(masterRecipeInfo));
            if(masterRecipeSelectImage != '' && masterRecipeSelectImage != '') {
                formData.append('recipeImage', masterRecipeSelectImage);
            }
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/recipe/updateUserRecipe`, {
                method: 'POST',
                body: formData
            }).then(res => res.json()).then(data => { return data })

            if (response.status) {
                // toast.success(response.message)
                if (user?.userType === 'Admin') {
                	navigate('/my-recipe');
                } else {
                	navigate('/my-recipe');
                }
                setLoading(false);
            } else {
                toast.error(response.message)
                setLoading(false);
            }
        } else {   
            formData.append('recipe', JSON.stringify(masterRecipeInfo)); 
            formData.append('recipeImage', masterRecipeSelectImage);
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/recipe/createUserRecipe`, {
                method: 'POST',
                body: formData
            }).then(res => res.json()).then(data => { return data })
    
            if (response.status) {
                toast.success(response.message)
                navigate('/my-recipe');
                setLoading(false);
            } else {
                toast.error(response.message)
                setLoading(false);
            }
        }
        
    }

    const handleRecipeInputFields = (e) => {
        setRecipeInfo({...recipeInfo , [e.target.name] : e.target.value});
    }

    // Add tag function
    const [masterTagDetails, setMasterTagDetails] = useState([]);
    const addTag = () => {
        const getTagInputValue = document.getElementById('recipe-tag').value;
        if(getTagInputValue != 'null' && getTagInputValue != ''){
            setMasterTagDetails((prev) => [...prev, getTagInputValue]);
            document.getElementById('recipe-tag').value = '';
        }
    }

    const addTagOnEnter = (event) => {
        if (event.key === "Enter") {
            addTag();
            event.preventDefault();
        }
    }

    const removeTag = (tagIndex) =>{
        if(tagIndex >= 0){
            setMasterTagDetails(prev => prev.filter(function(item, itemIndex){
                return itemIndex != tagIndex;
            }));
        }
    }

    const [masterMealType, setMasterMealType] = useState([])
    const defaultMealTypeDetails = [
        'breakfast',
        'lunch',
        'dinner',
        'dessert',
        'snack'
    ]
    const recipeMealType = (mealType) => {
        if (mealType != '' && mealType != null){
            if(document.getElementById("checkbox_" + mealType).checked != false){
                document.getElementById("checkbox_" + mealType).checked = false;
                setMasterMealType(prev => prev.filter(function(item){
                    return item != mealType;
                }));
            } else {
                document.getElementById("checkbox_" + mealType).checked = true;
                setMasterMealType(prev => [...prev, mealType]);
            }
        }
    }

    const [ selectIngredientFromApi , setSelectIngredientFromApi ] = useState(false);
    const selectApiIngredientDetail = (ing) => {        
        if(ing != '' && ing != null) {
            setSelectIngredientFromApi(true);
            setIngredientInfo({...ingredientInfo , 'name_of_ingredient' : ing});
            setIngredientInfo( prev => {
                return { ...prev, 'nutrition_form' : 'Nutrition API'}
            });
            setApiIngredientDetails([]);
        }

        /* setTimeout(() => {
            var event = new Event('change');
            document.getElementById('name-of-ingredient').dispatchEvent(event);
            document.getElementById('nutrition-form').dispatchEvent(event);
        }, 1000); */
    }

    const handleOnBlurIngredient = () => {
        setTimeout(function(){
            setShowApiIngredientDropDown(false)
        }, 500);
    }

    const modalBodyClickDetect = () => {
        if(document.activeElement == document.getElementById('name-of-ingredient')){
            setShowApiIngredientDropDown(true)
        } else {
            setShowApiIngredientDropDown(false)
        }
    }
    
    return (
        <div className='main-parent add-recipe-page'>
            <ToastContainer/>

            {loading && (
                <div className="loader-overlay">
                    <TailSpin height={80} width={80} color="#4fa94d" ariaLabel="tail-spin-loading" radius={1} visible={true} />
                </div>
            )}

            {/* Ingredient Delete Confirmation */}
            <ConfirmModal
                isOpen={showIngredientDeleteModal}
                setIsOpen={setShowIngredientDeleteModal}
                onOk={() => handleDeleteIngredient()}
                onCancel={() => {
                    setShowIngredientDeleteModal(false);
                }}
                title = 'Delete'
                message = 'Are you sure you want to delete this item ?'
                btnText = 'Delete'
            /> 

            <Modal centered show={showIngredientModal} size={'lg'} className="addingredients-modal"  backdrop="static" keyboard={false}>
                <ModalHeader className='px-0 pt-0'><h5 className='modal-title'>{ ingredientRecordId != '' && ingredientRecordId != null ? 'Update' : 'Add' } Ingredient</h5><button type="button" className="btn-close" onClick={handleCloseIngredientModal} aria-label="Close"></button></ModalHeader>
                <Modal.Body className='px-0' onClick={modalBodyClickDetect}>
	                <Tabs defaultActiveKey="Details" id="justify-tab-example-2" className="addingredients-tabs-sec" onSelect={fetchApiNutrientInfo}>
                        <Tab eventKey="Details" title="Details" className='details-tab'>
                            <form key={1} onSubmit={handleSubmitIngredient(onIngredientFormSubmit)} id="ingredient-form">
                                <Row className='form-row'>
                                    <Col sm={6}>
                                        <div className='form-group'>
                                            <label htmlFor='name_of_ingredient' className='label-tag'>Name of Ingredient </label>
                                            <div className='append-select-div'>
                                            <input type="text" className='input-tag w-100' autoComplete='off' id="name-of-ingredient" value={ingredientInfo?.name_of_ingredient}  name='name_of_ingredient' placeholder='Type Here' {...registerIngredient("name_of_ingredient", {required: true, setValueAs: (value) => value.trim()})} onKeyUp={fetchApiIngredientDetails} onChange={(handleIngredientInputFields)}/>  
                                            {ingredientErrors.name_of_ingredient && (<span className="text-danger">Please Enter Name Of Ingredient.</span>)}
                                            {/* onBlur={() => handleOnBlurIngredient()} */}
                                            {
                                                apiIngredientDetails.length && showApiIngredientDropDown != false > 0 
                                                ?
                                                    <div className='custom-select-dropdown'>
                                                        <ul>
                                                            {
                                                                apiIngredientDetails.map((apiIngredientDetail, index) => (
                                                                    <li key={index} onClick={() => selectApiIngredientDetail(apiIngredientDetail)}>{apiIngredientDetail}</li>
                                                                ))
                                                            }
                                                        </ul>
                                                    </div>
                                                :
                                                null
                                            }
                                            </div>
                                        </div>
                                    </Col>
                                    <Col sm={6}>
                                        <div className='form-group'>
                                            <label htmlFor='nutrition_form' className='label-tag'>Nutrition From </label>
                                            <div className='position-relative appended-input'>
                                            <input type="text" className='input-tag w-100' autoComplete='off' readOnly value={(selectIngredientFromApi != false ? 'Nutrition API' : 'Unavailable')} name='nutrition_form' id="nutrition-form" placeholder='Unavailable' {...registerIngredient("nutrition_form", {setValueAs: (value) => value.trim()})} onChange={(handleIngredientInputFields)}/>
                                            <button type="button" className="btn btn-secondary info-btn">
                                                {
                                                    selectIngredientFromApi != false
                                                    ?
                                                    <svg xmlns="http://www.w3.org/2000/svg" style={{background: '#74d473', borderRadius: '50%'}} width="16" height="16" fill="currentColor" className="bi bi-check" viewBox="0 0 16 16">
                                                    <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425z"/>
                                                  </svg>
                                                    :
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-info" viewBox="0 0 16 16">
                                                    <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0"></path>
                                                    </svg>
                                                }

{/* (<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0,0,256,256" width="16px" height="16px"><g fill="#74d473" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(5.12,5.12)"><path d="M43.171,10.925l-19.086,22.521l-9.667,-9.015l1.363,-1.463l8.134,7.585l17.946,-21.175c-4.204,-4.534 -10.205,-7.378 -16.861,-7.378c-12.683,0 -23,10.317 -23,23c0,12.683 10.317,23 23,23c12.683,0 23,-10.317 23,-23c0,-5.299 -1.806,-10.182 -4.829,-14.075z"></path></g></g></svg>) */}
                                            </button>
                                            </div>
                                        </div>
                                    </Col>  
                                    <Col sm={3}>
                                        <div className='form-group'>
                                            <label htmlFor='quantity' className='label-tag'>Quantity</label>
                                            <input type="text" className='input-tag' autoComplete='off' name='quantity' value={ingredientInfo?.quantity} placeholder='Quantity' onKeyPress={(e) => {isNumberKey(e)}} {...registerIngredient("quantity", {required: true, setValueAs: (value) => value.trim()})} onChange={(handleIngredientInputFields)}/>
                                            {ingredientErrors.quantity && (<span className="text-danger">Please Enter Quantity.</span>)}
                                            {/* <select name='quantity' className='input-tag'>
                                                <option value=''></option>
                                                <option value='lorem'>lorem</option>
                                            </select> */}
                                        </div>
                                    </Col>
                                    <Col sm={3}>
                                        <div className='form-group'>
                                            <label htmlFor='unit_of_measure' className='label-tag'>Unit of Measure</label>
                                            <select name='unit_of_measure' className='input-tag' value={ingredientInfo?.unit_of_measure} {...registerIngredient("unit_of_measure", {setValueAs: (value) => value.trim()})} onChange={(handleIngredientInputFields)}>
                                                {
                                                    DefaultIngredientUnitDetails.map((DefaultIngredientUnitDetail, index) => (
                                                        <option key={'unit_' + index} value={(DefaultIngredientUnitDetail != '(none)' ? DefaultIngredientUnitDetail : '')}>{DefaultIngredientUnitDetail}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                    </Col>
                                    <Col sm={6}>
                                        <div className='form-group'>
                                            <label htmlFor='category' className='label-tag'>Category</label>
                                            <select name='category' className='input-tag' value={ingredientInfo?.category} {...registerIngredient("category", {setValueAs: (value) => value.trim()})} onChange={(handleIngredientInputFields)}>
                                                <option value=''>Select</option>
                                                {
                                                    DefaultIngredientCategoryDetails.map((DefaultIngredientCategoryDetail, index) => (
                                                        <option key={'cat_' + index} value={DefaultIngredientCategoryDetail}>{DefaultIngredientCategoryDetail}</option>
                                                    ))
                                                }
                                                <option value='lorem'>lorem</option>
                                            </select>
                                        </div>
                                    </Col>
                                    <Col sm={12}>
                                        <div className='form-group'>
                                            <label htmlFor='preparation_notes' className='label-tag'>Preparation Notes</label>
                                            <textarea rows="4" className='input-tag h-auto'  name='preparation_notes' placeholder='Type Here' {...registerIngredient("preparation_notes", {setValueAs: (value) => value.trim()})} value={ingredientInfo?.preparation_notes} onChange={(handleIngredientInputFields)}/>
                                        </div>
                                    </Col>
                                </Row>
                                <div className='btn-div'>
                                    <button type="submit" name="submit" className="save-btn border-0">{ ingredientRecordId != '' && ingredientRecordId != null ? 'Update' : 'Add' } Ingredient</button>
                                </div>
                            </form>
                        </Tab>
                        <Tab eventKey="Nutrients" title="Nutrients" className='nutrients-tab'>
                            <div className='inner-card'>
                                <label className='label-tag amount-tag'>Amount Per Serving</label>
                                <div className='list-div'>
                                    <div className='list yellow-circle'><span className='name'>Calories</span> <span className='gm'>{ (apiNutrientInfo?.calories != '' && apiNutrientInfo?.calories != null && (typeof apiNutrientInfo != 'undefined' && apiNutrientInfo != null) ? (new Intl.NumberFormat('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(apiNutrientInfo?.calories)) : 0) }</span> <span className='perc text-end'>0 %</span></div>
                                    {
                                        ingredientNutrientsDetails.length > 0 && (typeof apiNutrientInfo != 'undefined' && apiNutrientInfo != null)
                                        ?
                                            ingredientNutrientsDetails.map((ingredientNutrientsDetail, index) => {
                                                const getNutrientColorCodeValue = getNutrientColorCode(ingredientNutrientsDetail);
                                                const getNutrientLabelValue = getNutrientLabel(ingredientNutrientsDetail); 
                                                
                                                const getApiTotalNutrientDetails = apiNutrientInfo?.totalNutrients;
                                                const getApiTotalNutrientInfo = getApiTotalNutrientDetails[ingredientNutrientsDetail];  
                                                const getApiTotalNutrientQuantity = (getApiTotalNutrientInfo != '' && getApiTotalNutrientInfo != null ? getApiTotalNutrientInfo.quantity : 0)
                                                const getApiTotalNutrientUnit = (getApiTotalNutrientInfo != '' && getApiTotalNutrientInfo != null ? getApiTotalNutrientInfo.unit : '')

                                                const getApiTotalDailyDetails = apiNutrientInfo?.totalDaily;
                                                const getApiTotalDailyInfo = getApiTotalDailyDetails[ingredientNutrientsDetail];                                                
                                                const getApiTotalDailyQuantity = (getApiTotalDailyInfo != '' && getApiTotalDailyInfo != null ? getApiTotalDailyInfo.quantity : 0)
                                                //const getApiTotalDailyUnit = (getApiTotalDailyInfo != '' && getApiTotalDailyInfo != null ? getApiTotalDailyInfo.unit : '')

                                                return (
                                                    <div className={`list ${getNutrientColorCodeValue}-circle`} key={index}>
                                                        <span className='name'>{getNutrientLabelValue}</span>
                                                        <span className='gm'>{ (getApiTotalNutrientQuantity != '' && getApiTotalNutrientQuantity != null ? (new Intl.NumberFormat('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(getApiTotalNutrientQuantity)) : '0') + (getApiTotalNutrientUnit != '' && getApiTotalNutrientUnit != null ? ' ' + getApiTotalNutrientUnit : '') }</span> <span className='perc text-end'>{ (getApiTotalDailyQuantity != '' && getApiTotalDailyQuantity != null ? (new Intl.NumberFormat('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(getApiTotalDailyQuantity)) : 0) } %</span>
                                                    </div>
                                                );
                                            })
                                        :
                                        null
                                    }
                                </div>
                                <div className='total-div'>
                                    <span className='daily-value'>% Daily Value = </span>
                                    <span className='output-value'>2,000 Calories Diet</span>
                                </div>
                            </div> 
                        </Tab>
	                </Tabs>
	            </Modal.Body>
            </Modal>

            <div className='bg-white py-3 px-4'>
                    <Row className='align-items-center'>
                        <Col lg={4}>
                            <div>
                                <h5 className='text-dark mb-0 fw-600 fs-5 left-border'>{ editRecipeId != null && editRecipeId != '' ? 'Update' : 'Add' } Recipe</h5>
                            </div>
                        </Col>
                    </Row>

                </div>

            {/* Direction Delete Confirmation */}
            <ConfirmModal
                isOpen={showDirectionDeleteModal}
                setIsOpen={setShowDirectionDeleteModal}
                onOk={() => handleDeleteDirection()}
                onCancel={() => {
                    setShowDirectionDeleteModal(false);
                }}
                title = 'Delete'
                message = 'Are you sure you want to delete this item ?'
                btnText = 'Delete'
            />

            <Modal centered show={showDirectionModal} size={'lg'} className="addingredients-modal adddirections-modal" onHide={handleCloseDirectionModal} backdrop="static" keyboard={false}>
                <ModalHeader className='px-0 pt-0'><h5 className='modal-title'>{ directionRecordId != '' && directionRecordId != null ? 'Update' : 'Add' } Direction</h5><button type="button" className="btn-close" onClick={handleCloseDirectionModal} aria-label="Close"></button></ModalHeader>
                <Modal.Body className='px-0'>
                    <form key={2} onSubmit={handleSubmitDirection(onDirectionFormSubmit)} id="direction-form">
                        <Row className='form-row'>
                            <Col sm={12}>
                                <div className='form-group'>
                                    <label htmlFor='direction' className='label-tag'>Add Direction</label>
                                    <textarea rows="4" className='input-tag h-auto'  name='direction' placeholder='Type Here' value={directionInfo?.direction} {...registerDirection("direction", {required: true, setValueAs: (value) => value.trim()})} onChange={(handleDirectionInputFields)} />
                                    {directionErrors.direction && (<span className="text-danger">Please Enter Direction.</span>)}
                                </div>
                            </Col>
                        </Row>
                        <div className='btn-div'>
                            <button type="submit" name="submit" className="save-btn border-0">{ directionRecordId != '' && directionRecordId != null ? 'Update' : 'Add' }    Direction</button>
                        </div>
                    </form>
	            </Modal.Body>
            </Modal>

            {/* Note Delete Confirmation */}
            <ConfirmModal
                isOpen={showNoteDeleteModal}
                setIsOpen={setShowNoteDeleteModal}
                onOk={() => handleDeleteNote()}
                onCancel={() => {
                    setShowNoteDeleteModal(false);
                }}
                title = 'Delete'
                message = 'Are you sure you want to delete this item ?'
                btnText = 'Delete'
            />

            <Modal centered show={showNoteModal} size={'lg'} className="addingredients-modal addnotes-modal" onHide={handleCloseNoteModal} backdrop="static" keyboard={false}>
                <ModalHeader className='px-0 pt-0'><h5 className='modal-title'>{ noteRecordId != '' && noteRecordId != null ? 'Update' : 'Add' } Note</h5><button type="button" className="btn-close" onClick={handleCloseNoteModal} aria-label="Close"></button></ModalHeader>
                <Modal.Body className='px-0'>
                    <form key={2} onSubmit={handleSubmitNote(onNoteFormSubmit)} id="note-form">
                        <Row className='form-row'>
                            <Col sm={12}>
                                <div className='form-group'>
                                    <label htmlFor='note' className='label-tag'>Add Note</label>
                                    <textarea rows="4" className='input-tag h-auto'  name='note' placeholder='Type Here' value={noteInfo?.note} {...registerNote("note", {required: true, setValueAs: (value) => value.trim()})} onChange={(handleNoteInputFields)} />
                                    {noteErrors.note && (<span className="text-danger">Please Enter Note.</span>)}
                                </div>
                            </Col>
                        </Row>
                        <div className='btn-div'>
                            <button type="submit" name="submit" className="save-btn border-0">{ noteRecordId != '' && noteRecordId != null ? 'Update' : 'Add' } Note</button>
                        </div>
                    </form>
	            </Modal.Body>
            </Modal>

            {/* Recipe Image Delete Confirmation */}
            <ConfirmModal
                isOpen={showRecipeImageDeleteModal}
                setIsOpen={setShowRecipeImageDeleteModal}
                onOk={() => handleDeleteRecipeSelectedImages(recipeImageDeleteId)}
                onCancel={() => {
                    setShowRecipeImageDeleteModal(false);
                }}
                title = 'Delete'
                message = 'Are you sure you want to delete selected image ?'
                btnText = 'Delete'
            /> 

            <div className='p-xl-5 p-3'>

                <form onSubmit={handleSubmitRecipe(async (data) => await onRecipeFormSubmit(data))} id="recipe-form" encType="multipart/form-data">

                    <div className='heading-div'>
                        <h4 className='heading-title'>Import File</h4>
                    </div>

                    <div className='import-from-web'>
                        <div className='container'>
                            <div className='inner-content'>
                                <input type="file" id="file" name="file" className='inner-file' />
                                <label htmlFor='file' className='img-div'>
                                    <img src={uploadIcon} alt="Upload Icon" className='upload-icon'/>
                                    <h4 className='import-text'>Import From Web</h4>
                                </label>
                            </div>
                        </div>
                    </div>

                    <div className='heading-div'>
                        <h4 className='heading-title'>Add Your Recipe</h4>
                    </div>

                    <div className='add-recipe-inner'>
                        <div className='inner-content'>
                            <Row>
                                <Col md={4}>
                                    <div className='add-img-div'>
                                        <input type="file" id="image-upload" className='inner-file' name="recipe_image" onChange={recipeImagePreview} />
                                        <label htmlFor='image-upload' className='img-div recipe-image-upload'>
                                            <img src={plusIcon} alt="Upload Icon" className='upload-icon'/>
                                            <h4 className='add-text'>Add Image</h4>
                                        </label>
                                    </div>
                                </Col>
                                <Col md={8}>
                                    <div className='img-row'>
                                        {
                                            recipeSelectedImages.length > 0
                                            ?
                                                recipeSelectedImages.map((data, index) => {
                                                    const { id, fileimage } = data;
                                                    return (
                                                        <div className='inner-img' key={id}>
                                                            <img src={fileimage} className="add-recipe-img"/>
                                                            <div className="round">
                                                                <input type="checkbox" id={`checkbox_${id}`} value={id} name="recipe_img_remove" onChange={deleteImageSelection}/>
                                                                <label htmlFor={`checkbox_${id}`}></label>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            :
                                                (dbEditSelectedImage != '' && dbEditSelectedImage != null && dbEditSelectedImage.length > 0 && editRecipeId != null && editRecipeId != '')
                                                ?
                                                    <div className='inner-img' key={'img-' + editRecipeId}>
                                                        <img src={dbEditSelectedImage} className="add-recipe-img"/>
                                                        <div className="round">
                                                            <input type="checkbox" id={`checkbox_${'img-' + editRecipeId}`} value={'img-' + editRecipeId} name="recipe_img_remove" onChange={deleteImageSelection}/>
                                                            <label htmlFor={`checkbox_${'img-' + editRecipeId}`}></label>
                                                        </div>
                                                    </div>
                                                :
                                                null
                                        }
                                    </div>
                                </Col>
                            </Row>
                            <div className='btns'>
                                {((recipeSelectedImages.length > 0) || (dbEditSelectedImage != '' && dbEditSelectedImage != null && dbEditSelectedImage.length > 0 && editRecipeId != null && editRecipeId != '')) ? <Link to={void(0)} className="green-outline-btn" onClick={deleteRecipeSelectedImages}>Delete Image</Link> : null}
                                {/* {recipeSelectedImages.length > 0 && editRecipeId ? <Link to={void(0)} className="green-btn" onClick={saveRecipeSelectedImages}>Save Images</Link> : null} */}
                            </div>
                        </div>
                    </div>

                    <div className='form-tag-div'>
                        <Row>
                            <Col md={5}>
                                <Row className='form-row'>
                                    <Col sm={12}>
                                        <div className='form-group'>
                                            <label htmlFor='title' className='label-tag'>Title</label>
                                            <input type="text" className='input-tag' name='title' placeholder='Title' value={recipeInfo?.title} {...registerRecipe("title", {required: true, setValueAs: (value) => value.trim()})} onChange={(handleRecipeInputFields)}/>
                                            {recipeErrors.title && (<span className="text-danger">Please Enter Title.</span>)}
                                        </div>
                                    </Col>
                                    <Col sm={6}>
                                        <div className='form-group'>
                                            <label htmlFor='time' className='label-tag'>Time</label>
                                            {/* <input type="text" className='input-tag' name='time' placeholder='Time'/> */}
                                            <TimePicker className='input-tag' format="mm:ss" name='time' placeholder='Time' value={recipeInfo?.time ? moment(recipeInfo?.time, 'mm:ss') : ''}  onSelect={(value) => {
                                                const time = new Date(value);
                                                const minutes = time.getMinutes();
                                                const seconds = time.getSeconds();
                                                
                                                const timeString = `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
                                                setRecipeInfo(prev => ({ ...prev, time: timeString }))
                                            }}/>
                                            {/* value={recipeInfo?.time} {...registerRecipe("time", {required: false, setValueAs: (value) => value.trim()})} onChange={(handleRecipeInputFields)} */}
                                        </div>
                                    </Col>
                                    <Col sm={6}>
                                        <div className='form-group'>
                                            <label htmlFor='servings' className='label-tag'>Servings</label>
                                            <input type="text" className='input-tag' name='servings' placeholder='Servings' value={recipeInfo?.servings} {...registerRecipe("servings", {required: false, setValueAs: (value) => value.trim()})} onChange={(handleRecipeInputFields)} onKeyPress={(e) => {isNumberKey(e)}}/>
                                        </div>
                                    </Col>
                                    <Col sm={12}>
                                        <label htmlFor='mealtype' className='label-tag'>Meal Type</label>
                                        <div className="mb-2 meal-type-tag add-recipe-tag">
                                            {
                                                defaultMealTypeDetails.map((defaultMealTypeDetail, index) => (
                                                    <span key={"mealtype_" + index} className={`meal-single-tag text-capitalize ${masterMealType?.includes(defaultMealTypeDetail) ? "category" : ""}`} onClick={() => recipeMealType(defaultMealTypeDetail)} >
                                                        {defaultMealTypeDetail}
                                                        <input type="checkbox" className='d-none' value={defaultMealTypeDetail} name="mealtype[]" id={"checkbox_" + defaultMealTypeDetail} />
                                                    </span>
                                                ))
                                            }
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={7}>
                                <div className='tag-sec'>
                                    <label htmlFor='add_tags' className='label-tag'>Add Tags</label>
                                    <div className='tag-bg'>
                                        <div className='tag-div'>
                                        {
                                            masterTagDetails.length > 0
                                            ?
                                                masterTagDetails.map((masterTagDetail, i) => (
                                                    <div className='tag' key={'tag_' + i}>{masterTagDetail}<button type="button" className="close" aria-label="Close"><span aria-hidden="true" onClick={() => {removeTag(i)}}>&times;</span></button></div>
                                                ))
                                            :
                                            null
                                        }
                                        </div>
                                        <div className='form-group tag-input-div'>
                                            <input type="text" className='input-tag' placeholder='Add Tag' id="recipe-tag" onKeyPress={(event) => {addTagOnEnter(event)}}></input>
                                            <button type="button" name="submit" className="save-btn border-0" onClick={() => {addTag()}}>Add Tag</button>
                                        </div>
                                    </div>
                                </div>  
                            </Col>
                        </Row>
                    </div>

                    <div className='bottom-sec'>
                        <Row>
                            <Col md={6}>
                                <Row className='bottom-tab-row'>
                                    <Col sm={12}>
                                        <div className='ingredients-div'>
                                            <label htmlFor='ingredients' className='label-tag'>Ingredients</label>    
                                            <label htmlFor='ingredients-upload' className='add-div'>
                                                {/* <input type="file" id="ingredients-upload" className='inner-file' /> */}
                                                <div className='inner-content'>
                                                    {
                                                        Object.keys(masterIngredientDetails).length > 0
                                                        ?
                                                            Object.entries(masterIngredientDetails).map(([ masterIngredientId, masterIngredientDetail ], i) => (
                                                                <ListingCardHorizonal key={masterIngredientId} elementTitle={masterIngredientDetail.name_of_ingredient} subtitle={masterIngredientDetail.nutrition_form} handleEditEvent={handleOpenIngredientModal} handleDeleteEvent={deleteSelectedIngredient} elementId={masterIngredientId} />
                                                            ))
                                                        :
                                                        <h5 className='sub-text'>No Ingredient Found</h5>
                                                    }
                                                    <div className='add-icon-div' onClick={() => { handleOpenIngredientModal() }}>
                                                        <img src={plusIcon} alt="Plus Icon" className='plus-icon'/>
                                                        <h4 className='add-text'>Add Ingredient</h4>
                                                    </div>
                                                </div>
                                            </label>  
                                        </div>
                                    </Col>
                                    <Col sm={12}>
                                        <div className='tabs-div'>
                                        <Tabs defaultActiveKey="Directions" id="justify-tab-example-2" className="nutrition-tabs-sec">
                                                <Tab eventKey="Directions" title="Directions" >
                                                    <div className='directions-div'>  
                                                        <label htmlFor='directions-upload' className='add-div'>
                                                            <div className='inner-content'>
                                                                {
                                                                    Object.keys(masterDirectionDetails).length > 0
                                                                    ?
                                                                        Object.entries(masterDirectionDetails).map(([ masterDirectionId, masterDirectionDetail ], i) => (
                                                                            <ListingCardHorizonal key={masterDirectionId} elementTitle={masterDirectionDetail.direction} handleEditEvent={handleOpenDirectionModal} handleDeleteEvent={deleteSelectedDirection} elementId={masterDirectionId} />
                                                                        ))
                                                                    :
                                                                    <h5 className='sub-text'>No Directions Found</h5>
                                                                }
                                                                <div className='add-icon-div' onClick={() => { handleOpenDirectionModal() }}>
                                                                    <img src={plusIcon} alt="Plus Icon" className='plus-icon'/>
                                                                    <h4 className='add-text'>Add Direction</h4>
                                                                </div>
                                                            </div>
                                                        </label>  
                                                    </div>
                                                </Tab>
                                                <Tab eventKey="Notes" title="Notes" >
                                                    <div className='notes-div'>  
                                                        <label htmlFor='notes-upload' className='add-div'>                                                        
                                                            <div className='inner-content'>
                                                                {
                                                                    Object.keys(masterNoteDetails).length > 0
                                                                    ?
                                                                        Object.entries(masterNoteDetails).map(([ masterNoteId, masterNoteDetail ], i) => (
                                                                            <ListingCardHorizonal key={masterNoteId} elementTitle={masterNoteDetail.note} handleEditEvent={handleOpenNoteModal} handleDeleteEvent={deleteSelectedNote} elementId={masterNoteId} />
                                                                        ))
                                                                    :
                                                                    <h5 className='sub-text'>No Notes Found</h5>
                                                                }
                                                                <div className='add-icon-div' onClick={() => { handleOpenNoteModal() }}>
                                                                    <img src={plusIcon} alt="Plus Icon" className='plus-icon'/>
                                                                    <h4 className='add-text'>Add Note</h4>
                                                                </div>
                                                            </div>
                                                        </label>  
                                                    </div>
                                                </Tab>
                                            </Tabs>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={6}>
                                <div className='nutrition-div'>
                                    <label htmlFor='nutrition' className='label-tag'>Nutrition</label>
                                    <div className='inner-card'>
                                        <label className='label-tag amount-tag'>Amount Per Serving</label>
                                        <div className='list-div'>
                                            <div className='list yellow-circle'><span className='name'>Calories</span> <span className='gm'>{ (typeof apiMasterNutrientDetails != 'undefined' && apiMasterNutrientDetails != null && apiMasterNutrientDetails?.calories != '' && apiMasterNutrientDetails?.calories != null ? ( Math.floor(apiMasterNutrientDetails?.calories) === apiMasterNutrientDetails?.calories ? (new Intl.NumberFormat('en-US').format(Math.floor(apiMasterNutrientDetails?.calories) || 0)) : (new Intl.NumberFormat('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(apiMasterNutrientDetails?.calories || 0)) ) : 0) }</span> <span className='perc text-end'>0 %</span></div>

                                            {
                                                Object.keys(apiMasterNutrientDetails).length > 0
                                                ?
                                                    Object.entries(apiMasterNutrientDetails).map(([ apiMasterNutrientCode, apiMasterNutrientDetail ], i) => {

                                                        if(apiMasterNutrientCode == 'calories' ){
                                                            return null;
                                                        }                                                       

                                                        const getNutrientColorCodeValue = getNutrientColorCode(apiMasterNutrientCode);
                                                        const getNutrientLabelValue = getNutrientLabel(apiMasterNutrientCode);
        
                                                        return (
                                                            <div className={`list ${getNutrientColorCodeValue}-circle`} key={i}>
                                                                <span className='name'>{getNutrientLabelValue}</span>
                                                                <span className='gm'>{ ( Math.floor(apiMasterNutrientDetail?.quantity) === apiMasterNutrientDetail?.quantity ? (new Intl.NumberFormat('en-US').format(Math.floor(apiMasterNutrientDetail?.quantity) || 0)) : (new Intl.NumberFormat('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(apiMasterNutrientDetail?.quantity || 0)) ) + (' ' + apiMasterNutrientDetail?.unit || '') }</span> <span className='perc text-end'>{ (new Intl.NumberFormat('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(apiMasterNutrientDetail?.perentage || 0)) } %</span>
                                                            </div>
                                                        );
                                                    })
                                                :
                                                null
                                            }
                                        </div>
                                        <div className='total-div'>
                                            <span className='daily-value'>% Daily Value = </span>
                                            <span className='output-value'>2,000 Calories Diet</span>
                                        </div>
                                    </div> 
                                </div>
                            </Col>
                        </Row>
                    </div>

                    <div className='btn-div'>
                        <button type="submit" name="submit" className="save-btn border-0">{ editRecipeId != null && editRecipeId != '' ? 'Update' : 'Save' } Recipe</button>
                    </div>

                </form>
            </div>
        </div>
    )
}
