import React from 'react'
import { DropdownButton, Dropdown } from 'react-bootstrap'

export default function ListingCardHorizonal(props) {
  return (
    <div className="direction-tabgrid">
      <span className="d-inline">
        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="22" viewBox="0 0 10 22" fill="none">
          <circle cx="2" cy="2" r="2" fill="#BAC8A8" />
          <circle cx="8" cy="2" r="2" fill="#BAC8A8" />
          <circle cx="2" cy="14" r="2" fill="#BAC8A8" />
          <circle cx="8" cy="14" r="2" fill="#BAC8A8" />
          <circle cx="2" cy="8" r="2" fill="#BAC8A8" />
          <circle cx="8" cy="8" r="2" fill="#BAC8A8" />
          <circle cx="2" cy="20" r="2" fill="#BAC8A8" />
          <circle cx="8" cy="20" r="2" fill="#BAC8A8" />
        </svg>
      </span>
      <div>
        <h4 className="text-dark mb-0 fw-600 fs-15">{props?.elementTitle}</h4>
        {props.hasOwnProperty('subtitle') != false && props.subtitle != '' && props.subtitle != null ? (
          <p className=" text-custom-grey fw-600 fs-15 mb-0">{props.subtitle}</p>
        ) : null}
      </div>
      <div>
        <DropdownButton
          className="meal-plan-item-action d-flex justify-content-end"
          variant="outline-light"
          title={
            <svg xmlns="http://www.w3.org/2000/svg" width="13" height="3" viewBox="0 0 13 3">
              <circle cx="1.5" cy="1.5" r="1.5"></circle>
              <circle cx="11.5" cy="1.5" r="1.5"></circle>
              <circle cx="6.5" cy="1.5" r="1.5"></circle>
            </svg>
          }
          align="end"
        >
          <Dropdown.Item
            as="button"
            type="button"
            onClick={() => {
              props?.handleEditEvent(props?.elementId)
            }}
          >
            Edit
          </Dropdown.Item>
          <Dropdown.Item
            as="button"
            type="button"
            onClick={() => {
              props?.handleDeleteEvent(props?.elementId)
            }}
          >
            Delete
          </Dropdown.Item>
        </DropdownButton>
      </div>
    </div>
  )
}
