import React from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import '../assets/css/Calendar.css'

const eventTypes = ['Upper Body', 'Lower Body', 'Metcon', 'Anterior', 'Posterior', 'Ant.Cond.']
const eventTypeClass = {
  'Upper Body': 'upper-body',
  'Lower Body': 'lower-body',
  Metcon: 'metcon',
  Anterior: 'anterior',
  Posterior: 'posterior',
  'Ant.Cond.': 'ath-conditioning'
}

const calculateWorkout = (startDate, currentDate) => {
  const millisecondsPerDay = 24 * 60 * 60 * 1000
  const daysDifference = Math.floor((currentDate - startDate) / millisecondsPerDay)
  const workoutIndex = daysDifference % eventTypes.length
  return eventTypes[workoutIndex]
}

const startDate = new Date('2023-12-31')

export default function UserWorkoutCalendar() {
  return (
    <div className="workout-calendar">
      <FullCalendar
        windowResize={false}
        plugins={[dayGridPlugin]}
        initialView="dayGridMonth"
        headerToolbar={{
          left: 'prev title next',
          center: '',
          right: ''
        }}
        dayHeaderFormat={{
          weekday: 'long'
        }}
        eventSources={[
          {
            className: 'workout-type',
            events: (fetchInfo, successCallback) => {
              const events = []
              for (let date = new Date(fetchInfo.start); date <= fetchInfo.end; date.setDate(date.getDate() + 1)) {
                const title = calculateWorkout(startDate, date)
                events.push({
                  title: title,
                  start: new Date(date).toISOString().slice(0, 10),
                  classNames: [eventTypeClass[title]]
                })
              }
              successCallback(events)
            }
          }
        ]}
      />
    </div>
  )
}
