import React from 'react'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'react-bootstrap'

const ConfirmModal = ({ isOpen, setIsOpen, title, message, btnText, onOk, onCancel, type = 'delete' }) => {
  const toggle = () => setIsOpen((prev) => !prev)
  return (
    <Modal centered show={isOpen} size={'md'} onHide={toggle} backdrop="static" keyboard={false}>
      <ModalHeader className="text-capitalize">
        {title ? title : type}
        <button type="button" className="btn-close" onClick={onCancel || toggle} aria-label="Close"></button>
      </ModalHeader>
      <ModalBody>{type === 'delete' ? message || 'Are you sure you want to delete this item ?' : message || 'Are you sure you proceed?'}</ModalBody>
      <ModalFooter tag={'div'} className="transparant border-0 bg-light bg-light">
        <Button onClick={onCancel || toggle} className="btn-secondary">
          Cancel
        </Button>
        <Button
          onClick={() => {
            onOk()
            toggle()
          }}
          type="submit"
          className="btn-danger"
        >
          {btnText || type}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default ConfirmModal
