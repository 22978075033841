import React, { Fragment, useEffect, useState } from 'react'
import { Badge, Button, Card, Col, Form, InputGroup, Modal, Row } from 'react-bootstrap'
import { Player } from 'video-react'
import 'video-react/dist/video-react.css'
import './style.css'
import Select from 'react-select'
import axios from 'axios'

export default function ExerciseDatabase() {
  const [exercises, setExercises] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [exerciseName, setExerciseName] = useState('')
  const perPage = 4
  const [totalPages, setTotalPages] = useState()
  const [loading, setLoading] = useState(false)
  const [filterData, setFilterData] = useState({
    primaryMusclesWorked: [],
    secondaryMusclesWorked: [],
    equipment: [],
    difficultyLevel: [],
    exerciseType: []
  })

  const [query, setQuery] = useState({
    exerciseName: '',
    primaryMusclesWorked: [],
    secondaryMusclesWorked: [],
    equipment: [],
    difficultyLevel: [],
    exerciseType: []
  })

  const handlePageClick = (pageNumber) => setCurrentPage(pageNumber)

  const [exerciseModalPopUp, setExerciseModalPopUp] = useState(false)
  const [exerciseFilterPopUp, setExerciseFilterPopUp] = useState(false)

  const fetchExercises = async (newData = false) => {
    setLoading(true)
    axios
      .get('/admin/getExercises', { params: { page: currentPage, perPage, ...query } })
      .then((response) => {
        const {
          data: {
            data: { data, totalPages }
          }
        } = response
        setExercises(newData ? data : [...exercises, ...data])
        setTotalPages(totalPages)
      })
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    fetchExercises().then()
  }, [currentPage])

  useEffect(() => {
    fetchExercises(true).then()
  }, [query])

  useEffect(() => {
    fetchExercises().then()
  }, [])

  return (
    <Fragment>
      <div className="exercise-database">
        <div className="search-bar bg-white py-3 px-4">
          <div className="d-flex justify-content-between w-100">
            <InputGroup>
              <Form.Control
                placeholder="Search what you need"
                className="border-end-0"
                value={exerciseName}
                onChange={(event) => setExerciseName(event.target.value)}
              />
              <Button
                variant="outline-secondary"
                className="border-start-0"
                style={{ border: '1px solid #dee2e6' }}
                onClick={() => setQuery({ ...query, exerciseName })}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" className="bi bi-search">
                  <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                </svg>
              </Button>
            </InputGroup>
            <Button className="btn btn-light" onClick={() => setExerciseFilterPopUp(true)}>
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" viewBox="0 0 18 16" fill="none">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M3.75 2C3.33579 2 3 2.33579 3 2.75V4.25001H15V2.75C15 2.33579 14.6642 2 14.25 2H3.75ZM14.8367 5.75001H3.16332C3.24908 5.91841 3.3668 6.07095 3.51224 6.19821L8.01228 10.1357C8.57778 10.6305 9.4222 10.6305 9.98778 10.1357L14.4878 6.19821C14.6332 6.07095 14.751 5.91841 14.8367 5.75001Z"
                  fill="#898989"
                />
                <path fillRule="evenodd" d="M10.5 13.0287V8H7.5V14.5287L10.5 13.0287Z" fill="#898989" />
              </svg>
              <span className="ps-1">Filter</span>
            </Button>
          </div>
        </div>
        <Row className="g-4">
          {exercises.map((card, index) => (
            <Col xs={12} sm={6} md={6} lg={4} xxl={3} key={index}>
              <Card className="exercise">
                <div className="position-relative">
                  <div className="position-absolute" onClick={() => setExerciseModalPopUp(card)}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="65" height="68" viewBox="0 0 65 68" fill="none">
                      <rect width="64.7093" height="67.0589" rx="32.3547" fill="black" fillOpacity="0.45" />
                      <path
                        d="M22.0002 42.3481L22.0002 24.7078C22.0002 21.0949 25.9264 18.8277 29.06 20.6341L36.7096 25.0396L44.3593 29.4635C47.4929 31.2699 47.4929 35.7859 44.3593 37.5924L36.7096 42.0163L29.06 46.4217C25.9264 48.2281 22.0002 45.9793 22.0002 42.3481Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                  <Card.Img
                    variant="top"
                    src={
                      card.webVideo ||
                      'https://images.unsplash.com/photo-1541534741688-6078c6bfb5c5?q=80&w=3538&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
                    }
                  />
                </div>
                <Card.Body className="data">
                  <Card.Title as="h4">{card.exerciseName}</Card.Title>
                  <div>
                    Primary Muscles :
                    {card.primaryMusclesWorked.split(',').map((primaryMuscle, index) => (
                      <Badge className="badge-color" key={index}>
                        {primaryMuscle}
                      </Badge>
                    ))}
                  </div>
                  <div>
                    Secondary Muscles :
                    {card.secondaryMusclesWorked.split(',').map((secondaryMuscle, index) => (
                      <Badge color="primary" key={index}>
                        {secondaryMuscle}
                      </Badge>
                    ))}
                  </div>
                  <div>
                    Equipment Needed :
                    {card.equipment.split(',').map((equipmentNeeded, index) => (
                      <Badge color="primary" key={index}>
                        {equipmentNeeded}
                      </Badge>
                    ))}
                  </div>
                  <div>
                    Exercise Type :
                    {card.exerciseType.split(',').map((exerciseType, index) => (
                      <Badge color="primary" key={index}>
                        {exerciseType}
                      </Badge>
                    ))}
                  </div>
                  <div>
                    Difficulty Level :
                    {card.difficultyLevel.split(',').map((difficultyLevel, index) => (
                      <Badge color="primary" key={index}>
                        {difficultyLevel}
                      </Badge>
                    ))}
                  </div>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
        {loading && <h3 className="text-center py-5">Loading...</h3>}
        {!loading && exercises.length !== 0 && currentPage !== totalPages && (
          <div className="d-flex justify-content-center my-5">
            <Button onClick={() => handlePageClick(currentPage + 1)} className="btn-more">
              More...
            </Button>
          </div>
        )}
      </div>

      <Modal
        centered
        show={exerciseModalPopUp !== false}
        size="xl"
        onHide={() => setExerciseModalPopUp(false)}
        className="exercise-database-modal"
        tabindex="-1"
      >
        <Modal.Header closeButton>
          <Modal.Title>{exerciseModalPopUp && exerciseModalPopUp.exerciseName}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="exercise">
          <Row>
            <Col md={8}>
              <div className="video">
                <Player playsInline src="https://media.w3.org/2010/05/sintel/trailer_hd.mp4" />
              </div>
            </Col>
            <Col md={4}>
              <Card className="border-0">
                <Card.Body className="data">
                  <div className="primary-muscle">
                    Primary Muscles :
                    {exerciseModalPopUp &&
                      exerciseModalPopUp?.primaryMusclesWorked?.split(',').map((primaryMuscle, index) => (
                        <Badge className="badge-color" key={index}>
                          {primaryMuscle}
                        </Badge>
                      ))}
                  </div>
                  <div className="primary-muscle">
                    Secondary Muscles :
                    {exerciseModalPopUp &&
                      exerciseModalPopUp?.secondaryMusclesWorked?.split(',').map((secondaryMuscle, index) => (
                        <Badge className="badge-color" key={index}>
                          {secondaryMuscle}
                        </Badge>
                      ))}
                  </div>
                  <div className="primary-muscle">
                    Equipment Needed :
                    {exerciseModalPopUp &&
                      exerciseModalPopUp?.equipment?.split(',').map((equipmentNeeded, index) => (
                        <Badge className="badge-color" key={index}>
                          {equipmentNeeded}
                        </Badge>
                      ))}
                  </div>
                  <div className="primary-muscle">
                    Exercise Type :
                    {exerciseModalPopUp &&
                      exerciseModalPopUp?.exerciseType?.split(',').map((exerciseType, index) => (
                        <Badge className="badge-color" key={index}>
                          {exerciseType}
                        </Badge>
                      ))}
                  </div>
                  <div className="primary-muscle">
                    Difficulty Level :
                    {exerciseModalPopUp &&
                      exerciseModalPopUp?.difficultyLevel?.split(',').map((difficultyLevel, index) => (
                        <Badge className="badge-color" key={index}>
                          {difficultyLevel}
                        </Badge>
                      ))}
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
      <Modal className="exercise-filter-modal" show={exerciseFilterPopUp !== false} size="md" onHide={() => setExerciseFilterPopUp(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body className="filter">
          <div className="form">
            <Form.Group className="mb-2">
              <Form.Label>Primary Muscles</Form.Label>
              <Select
                id="primaryMuscles"
                placeholder="Select"
                isClearable={true}
                isMulti={true}
                options={[
                  { label: 'Abdominals', value: 'Abdominals' },
                  { label: 'Hips', value: 'Hips' },
                  { label: 'Hamstrings', value: 'Hamstrings' },
                  { label: 'Quadriceps', value: 'Quadriceps' },
                  { label: 'Glutes', value: 'glutes' },
                  { label: 'Calves', value: 'calves' },
                  { label: 'Quadriceps', value: 'Quadriceps' }
                ]}
                onChange={(data) =>
                  setFilterData({
                    ...filterData,
                    primaryMusclesWorked: data.map(({ value }) => value)
                  })
                }
              />
            </Form.Group>
            <Form.Group className="mb-2">
              <Form.Label>Secondary Muscles</Form.Label>
              <Select
                id="secondaryMuscles"
                placeholder="Select"
                isClearable={true}
                isMulti={true}
                options={[
                  { label: 'Shoulders', value: 'Shoulders' },
                  { label: 'Chest', value: 'chest' },
                  { label: 'Upper/Mid Back', value: 'upper/mid back' },
                  { label: 'Low Back', value: 'low back' },
                  { label: 'Glutes', value: 'glutes' },
                  { label: 'Hips', value: 'hips' },
                  { label: 'Quadriceps', value: 'quadriceps' },
                  { label: 'Hamstrings', value: 'hamstrings' },
                  { label: 'Calves', value: 'calves' }
                ]}
                onChange={(data) =>
                  setFilterData({
                    ...filterData,
                    secondaryMusclesWorked: data.map(({ value }) => value)
                  })
                }
              />
            </Form.Group>
            <Form.Group className="mb-2">
              <Form.Label>Equipment Needed</Form.Label>
              <Select
                id="equipmentNeeded"
                placeholder="Select"
                isClearable={true}
                isMulti={true}
                options={[{ label: 'Bodyweight', value: 'Bodyweight' }]}
                onChange={(data) =>
                  setFilterData({
                    ...filterData,
                    equipment: data.map(({ value }) => value)
                  })
                }
              />
            </Form.Group>
            <Form.Group className="mb-2">
              <Form.Label>Exercise Type</Form.Label>
              <Select
                id="exerciseType"
                placeholder="Select"
                isClearable={true}
                isMulti={true}
                options={[
                  { label: 'Upper Body', value: 'Upper body' },
                  { label: 'Lower Body', value: 'Lower body' },
                  { label: 'Anterior', value: 'Anterior' },
                  { label: 'Posterior', value: 'Posterior' },
                  { label: 'Metcon', value: 'Metcon' },
                  { label: 'Athletic Conditioning', value: 'Athletic Conditioning' }
                ]}
                onChange={(data) =>
                  setFilterData({
                    ...filterData,
                    exerciseType: data.map(({ value }) => value)
                  })
                }
              />
            </Form.Group>
            <Form.Group className="mb-2">
              <Form.Label>Difficulty Level</Form.Label>
              <Select
                id="difficultyLevel"
                placeholder="Select"
                isClearable={true}
                isMulti={true}
                options={[
                  { label: 'Beginner', value: 'Beginner' },
                  { label: 'HipIntermediates', value: 'Intermediate' },
                  { label: 'Advanced', value: 'Advanced' }
                ]}
                onChange={(data) =>
                  setFilterData({
                    ...filterData,
                    difficultyLevel: data.map(({ value }) => value)
                  })
                }
              />
            </Form.Group>
            <Button className="button" onClick={() => setQuery({ ...query, ...filterData })}>
              Filter Exercises
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </Fragment>
  )
}
