import React, { useEffect, useState } from 'react';
//import TopBar from '../components/TopBar';
import { Button, Col, Row } from 'react-bootstrap';
import FavouriteRecipeCard from '../User_components/FavouriteRecipeCard';
import { ToastContainer } from 'react-toastify';
import { TailSpin } from 'react-loader-spinner';
import InfiniteScroll from 'react-infinite-scroll-component';

export default function FavoriteRecipe() {
    const [loading, setLoading] = useState(false);
    const [favouriteData, setFavouriteData] = useState([]);
    const [fetchFavData, setFetchFavData] = useState(false);
    const [dataFound, setDataFound] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [favouriteRecipeRequestPage, setFavouriteRecipeRequestPage] = useState(1);

    const fetchData = async () => {
        setLoading(true);
        try {
            const userdata = JSON.parse(localStorage.getItem('userdata'));
            const token = userdata.token;

            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/favtry/getallMyFavourire`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify( { pageNo : favouriteRecipeRequestPage  } )
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);

            }

            const data = await response.json();

            if(data?.start > 1){
                setFavouriteData([...favouriteData, ...data?.data]);
            } else {
                setFavouriteData(data?.data);
            }

            setLoading(false);
            setDataFound(true);
            setTotalRecords(data?.totalRecords || 0);
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
            setDataFound(false);
        }
    };

    useEffect(() => {
        fetchData();
        setFetchFavData(false)
    }, [fetchFavData, favouriteRecipeRequestPage]);

    const fetchNextData = () => {
        setFavouriteRecipeRequestPage((prev) => (parseInt(prev) + 1))
    }

    return (
        <div>
            <ToastContainer />
            {loading && (
                <div className="loader-overlay">
                    <TailSpin height={80} width={80} color="#4fa94d" ariaLabel="tail-spin-loading" radius={1} visible={true} />
                </div>
            )}
            <div className='main-content mt-1'>
                <div className='bg-white p-3'>
                    <Row>
                        <Col lg={4}>
                            <div style={{ height: '60px' }}>
                                <h5 className='text-dark mb-0 fw-600 fs-5 left-border'>Favorite Recipes</h5>
                                <p className='text-custom-grey fw-600 fs-17 ps-2 mb-0'>{favouriteData && favouriteData?.length > 0 ? favouriteData?.length : 0} Recipes</p>
                            </div>
                        </Col>
                        <Col lg={8}>
                            <div className='text-end'>
                                <Button className='text-custom-grey mb-0 fw-600 fs-17 bg-none border border-gray px-3'><svg className='me-2' xmlns="http://www.w3.org/2000/svg" width="17" height="18" viewBox="0 0 17 18" fill="none">
                                    <path d="M0.918945 0H16.7566V2.56205H0.918945V0Z" fill="#959595" />
                                    <path d="M9.30334 10.7136H6.74145V13.9742L10.9338 17.2348L10.9336 10.2476L15.3355 3.74951H2.33936L6.50838 9.78179H9.30322C9.55942 9.78179 9.76899 10.038 9.76899 10.2942C9.76899 10.5504 9.55941 10.7133 9.30322 10.7133L9.30334 10.7136Z" fill="#959595" />
                                </svg>Filter</Button>
                            </div>
                        </Col>
                    </Row>
                </div>
                {<div className='p-xl-5 p-3'>
                    <Row>
                        <Col lg={12}>
                            {
                                favouriteData && favouriteData?.length > 0
                                ?
                                    <InfiniteScroll key="recipe-infinite-scroll" dataLength={favouriteData?.length} next={fetchNextData} hasMore={favouriteData?.length !== totalRecords} height={800} >
                                        <div className='recipe-grid'>
                                            {
                                                favouriteData.map((recipe, i) => (
                                                    <FavouriteRecipeCard key={"recipecard-" + i} data={recipe} updateFetchDataStatus={setFetchFavData} />
                                                ))
                                            }
                                        </div>
                                    </InfiniteScroll>
                                :
                                    null
                            }
                        </Col>
                    </Row>
                </div>}

                <div className='p-xl-5 p-3'>

                </div>
            </div>
        </div>
    )
}
