import React, { useState, useEffect } from 'react'
import { Button, Col, Row, Form, Tab, Tabs, Modal } from 'react-bootstrap'
import Box from '@mui/material/Box'
import Slider from '@mui/material/Slider'
import MultiSelectWithvalue from '../components/MultiSelectWithvalue'
import Accordion from 'react-bootstrap/Accordion'
import SladPic from '../assets/images/greek-salad-food 2.png'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { TailSpin } from 'react-loader-spinner'
import * as FilterSelectionList from './FilterSelectionList'

export default function RecipeFilter(props) {
  //console.log("props");
  //console.log(props);
  //console.log(props?.updateSearchRecipeParams)
  //console.log(props?.searchRecipeParamIntialValue)
  const filterRecipe = props?.filterRecipe
  const updateFilterValue = props?.updateFilterRecipe
  const updateNextPageLink = props?.updateNextPageLink

  const defaultMaxFilterValues = FilterSelectionList.defaultMaxFilterValues
  const dietSelectionMasterList = FilterSelectionList.dietSelectionList
  const healthSelectionList = FilterSelectionList.healthSelectionList
  const dishSelectionList = FilterSelectionList.dishSelectionList
  const cuisineSelectionList = FilterSelectionList.cuisineSelectionList

  const getValuesByKey = (arr, key) => {
    return arr.map((item) => item[key])
  }
  const dietSelectionList = dietSelectionMasterList
  //console.log("dietSelectionList");
  //console.log(dietSelectionList)

  const Category = (category) => {
    if (category) {
      if (filterRecipe.mealType.includes(category)) {
        // Category is already selected, so remove it
        const categoryArray = filterRecipe.mealType
        const updatedValues = filterRecipe.mealType.filter((item) => item !== category)
        updateFilterValue({ ...filterRecipe, mealType: updatedValues })
      } else {
        // Category is not selected, so add it
        const categoryArray = filterRecipe.mealType
        const updatedValues = [...categoryArray, category]
        updateFilterValue({ ...filterRecipe, mealType: updatedValues })
      }
    }
    updateNextPageLink(null)
  }

  const FilterRecipeRange = (e) => {
    const { name, value } = e.target
    const updatedValues = { ...filterRecipe, [name]: value }
    updateFilterValue(updatedValues)
    updateNextPageLink(null)
  }
  const FilterRecipeInput = (e) => {
    const { name, value } = e.target
    const newValue = value !== null && value !== '' ? value : null
    //console.log(newValue);
    //console.log(newValue.trim());
    const updatedValues = { ...filterRecipe, [name]: newValue }
    updateFilterValue(updatedValues)
    updateNextPageLink(null)
  }
  const FilterRecipeMultiSelect = (data, e) => {
    const { name, value } = e
    const selectedValues = data.map((value) => value.value)
    const updatedValues = { ...filterRecipe, [name]: selectedValues }
    updateFilterValue(updatedValues)
    updateNextPageLink(null)
  }

  return (
    <Tabs defaultActiveKey="Basic" id="justify-tab-example" className="p-0">
      <Tab eventKey="Basic" title="Basic">
        <div>
          <div>
            <h5 className="my-2">Meal Type</h5>
            <div className="mb-2 meal-type-tag">
              <span
                className={`meal-single-tag ${filterRecipe?.mealType?.includes('breakfast') ? 'category' : ''}`}
                onClick={() => Category('breakfast')}
              >
                Breakfast
              </span>
              <span className={`meal-single-tag ${filterRecipe?.mealType?.includes('lunch') ? 'category' : ''}`} onClick={() => Category('lunch')}>
                Lunch
              </span>
              <span className={`meal-single-tag ${filterRecipe?.mealType?.includes('dinner') ? 'category' : ''}`} onClick={() => Category('dinner')}>
                Dinner
              </span>
              <span
                className={`meal-single-tag ${filterRecipe?.mealType?.includes('teatime') ? 'category' : ''}`}
                onClick={() => Category('teatime')}
              >
                Dessert
              </span>
              <span className={`meal-single-tag ${filterRecipe?.mealType?.includes('snack') ? 'category' : ''}`} onClick={() => Category('snack')}>
                Snack
              </span>
            </div>
            <Form.Group className="mb-0 px-2" controlId="formBasicEmail">
              <Form.Label className="d-flex justify-content-between mb-0">
                <span className="fw-normal fs-6 text-black">{filterRecipe?.time[0]} Minutes</span>
                <span className="fw-normal fs-6 text-black">
                  {filterRecipe?.time[1]}
                  {filterRecipe?.time[1] === defaultMaxFilterValues['time'] ? '+' : ''} Minutes
                </span>
              </Form.Label>
              <Box>
                <Slider
                  className="w-100"
                  getAriaLabel={() => 'range'}
                  sx={{
                    width: 400,
                    height: 8,
                    color: '#F36F27'
                  }}
                  min={0}
                  max={defaultMaxFilterValues['time']}
                  name="time"
                  value={filterRecipe?.time}
                  onChange={FilterRecipeRange}
                  valueLabelDisplay="auto"
                />
              </Box>
            </Form.Group>
            <Form.Group className="mb-0 px-2" controlId="formBasicEmail">
              <Form.Label className="d-flex justify-content-between mb-0">
                <span className="fw-normal fs-6 text-black">{filterRecipe?.ingr[0]} Ingredients</span>
                <span className="fw-normal fs-6 text-black">
                  {filterRecipe?.ingr[1]}
                  {filterRecipe?.ingr[1] === defaultMaxFilterValues['ingr'] ? '+' : ''} Ingredients
                </span>
              </Form.Label>
              <Box>
                <Slider
                  className="w-100"
                  getAriaLabel={() => 'range'}
                  sx={{
                    width: 400,
                    height: 8,
                    color: '#F36F27'
                  }}
                  min={0}
                  max={defaultMaxFilterValues['ingr']}
                  name="ingr"
                  value={filterRecipe?.ingr}
                  onChange={FilterRecipeRange}
                  valueLabelDisplay="auto"
                />
              </Box>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label className="text-black fw-600 fs-17">Tags (Comma Separated Value)</Form.Label>
              <Form.Control
                className="shadow-none border border-2 fw-600 text-black py-3"
                type="text"
                placeholder=""
                name="tag"
                value={filterRecipe.tag}
                onChange={FilterRecipeInput}
              />
            </Form.Group>
            <Form.Group controlId="exampleForm.ControlInput1">
              <Form.Label className="text-black fw-600 fs-17">Exclude ingredients (Comma Separated Value)</Form.Label>
              <Form.Control
                className="shadow-none border border-2 fw-600 text-black py-3"
                type="text"
                placeholder=""
                name="excluded"
                value={filterRecipe.excluded}
                onChange={FilterRecipeInput}
              />
            </Form.Group>
          </div>
        </div>
      </Tab>
      <Tab eventKey="Advanced" title="Advanced">
        <div>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label className="text-black fw-600 fs-17">Diet Types</Form.Label>
            <MultiSelectWithvalue
              data={dietSelectionList}
              handleClose={true}
              value={filterRecipe?.diet}
              name={'diet'}
              handleChange={FilterRecipeMultiSelect}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label className="text-black fw-600 fs-17">Health Labels</Form.Label>
            <MultiSelectWithvalue
              data={healthSelectionList}
              handleClose={true}
              value={filterRecipe?.health}
              name={'health'}
              handleChange={FilterRecipeMultiSelect}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label className="text-black fw-600 fs-17">Dish Type</Form.Label>
            <MultiSelectWithvalue
              data={dishSelectionList}
              handleClose={true}
              value={filterRecipe?.dishType}
              name={'dishType'}
              handleChange={FilterRecipeMultiSelect}
            />
          </Form.Group>
          <Form.Group controlId="exampleForm.ControlInput1">
            <Form.Label className="text-black fw-600 fs-17">Cuisine Type</Form.Label>
            <MultiSelectWithvalue
              data={cuisineSelectionList}
              handleClose={true}
              value={filterRecipe?.cuisineType}
              name={'cuisineType'}
              handleChange={FilterRecipeMultiSelect}
            />
          </Form.Group>
        </div>
      </Tab>
      <Tab eventKey="Nutrition" title="Nutrition">
        <div>
          <Form.Group className="mb-0 px-2" controlId="formBasicEmail">
            <Form.Label className="d-flex justify-content-between mb-0">
              <span className="fw-normal fs-6 text-black">{filterRecipe?.calories[0]} Calories</span>
              <span className="fw-normal fs-6 text-black">
                {filterRecipe?.calories[1]}
                {filterRecipe?.calories[1] === defaultMaxFilterValues['calories'] ? '+' : ''} Calories
              </span>
            </Form.Label>
            <Box>
              <Slider
                className="w-100"
                getAriaLabel={() => 'range'}
                sx={{
                  width: 400,
                  height: 8,
                  color: '#F36F27'
                }}
                min={0}
                max={defaultMaxFilterValues['calories']}
                name="calories"
                value={filterRecipe?.calories}
                onChange={FilterRecipeRange}
                valueLabelDisplay="auto"
              />
            </Box>
          </Form.Group>
          <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <Form.Label className="text-green fw-600 fs-17">Macros</Form.Label>

                {/* <Form.Control className='shadow-none border border-2 fw-600 text-black py-3' type="text" placeholder="" /> */}
              </Accordion.Header>
              <Accordion.Body>
                <Form.Group className="mb-0 px-2" controlId="formBasicEmail">
                  <Form.Label className="d-flex justify-content-between mb-0">
                    <span className="fw-normal fs-6 text-black">{filterRecipe?.FAT[0]} g Fat</span>
                    <span className="fw-normal fs-6 text-black">
                      {filterRecipe?.FAT[1]}
                      {filterRecipe?.FAT[1] === defaultMaxFilterValues['FAT'] ? '+' : ''} g Fat
                    </span>
                  </Form.Label>
                  <Box sx={{ width: 300 }}>
                    <Slider
                      getAriaLabel={() => 'range'}
                      sx={{
                        width: 400,
                        height: 8,
                        color: '#F36F27'
                      }}
                      min={0}
                      max={defaultMaxFilterValues['FAT']}
                      name="FAT"
                      value={filterRecipe?.FAT}
                      onChange={FilterRecipeRange}
                      valueLabelDisplay="auto"
                      // getAriaValueText={valuetext}
                    />
                  </Box>
                </Form.Group>
                <Form.Group className="mb-0 px-2" controlId="formBasicEmail">
                  <Form.Label className="d-flex justify-content-between mb-0">
                    <span className="fw-normal fs-6 text-black">{filterRecipe?.FASAT[0]} g Saturated</span>
                    <span className="fw-normal fs-6 text-black">
                      {filterRecipe?.FASAT[1]}
                      {filterRecipe?.FASAT[1] === defaultMaxFilterValues['FASAT'] ? '+' : ''} g Saturated
                    </span>
                  </Form.Label>
                  <Box sx={{ width: 300 }}>
                    <Slider
                      getAriaLabel={() => 'range'}
                      sx={{
                        width: 400,
                        height: 8,
                        color: '#F36F27'
                      }}
                      min={0}
                      max={defaultMaxFilterValues['FASAT']}
                      name="FASAT"
                      value={filterRecipe?.FASAT}
                      onChange={FilterRecipeRange}
                      valueLabelDisplay="auto"
                      // getAriaValueText={valuetext}
                    />
                  </Box>
                </Form.Group>
                <Form.Group className="mb-0 px-2" controlId="formBasicEmail">
                  <Form.Label className="d-flex justify-content-between mb-0">
                    <span className="fw-normal fs-6 text-black">{filterRecipe?.FAPU[0]} g Polyunsaturated</span>
                    <span className="fw-normal fs-6 text-black">
                      {filterRecipe?.FAPU[1]}
                      {filterRecipe?.FAPU[1] === defaultMaxFilterValues['FAPU'] ? '+' : ''} g Polyunsaturated
                    </span>
                  </Form.Label>
                  <Box sx={{ width: 300 }}>
                    <Slider
                      getAriaLabel={() => 'range'}
                      sx={{
                        width: 400,
                        height: 8,
                        color: '#F36F27'
                      }}
                      min={0}
                      max={defaultMaxFilterValues['FAPU']}
                      name="FAPU"
                      value={filterRecipe?.FAPU}
                      onChange={FilterRecipeRange}
                      valueLabelDisplay="auto"
                      // getAriaValueText={valuetext}
                    />
                  </Box>
                </Form.Group>
                <Form.Group className="mb-0 px-2" controlId="formBasicEmail">
                  <Form.Label className="d-flex justify-content-between mb-0">
                    <span className="fw-normal fs-6 text-black">{filterRecipe?.FAMS[0]} mg Monounsaturated</span>
                    <span className="fw-normal fs-6 text-black">
                      {filterRecipe?.FAMS[1]}
                      {filterRecipe?.FAMS[1] === defaultMaxFilterValues['FAMS'] ? '+' : ''} mg Monounsaturated
                    </span>
                  </Form.Label>
                  <Box sx={{ width: 300 }}>
                    <Slider
                      getAriaLabel={() => 'range'}
                      sx={{
                        width: 400,
                        height: 8,
                        color: '#F36F27'
                      }}
                      min={0}
                      max={defaultMaxFilterValues['FAMS']}
                      name="FAMS"
                      value={filterRecipe?.FAMS}
                      onChange={FilterRecipeRange}
                      valueLabelDisplay="auto"
                      // getAriaValueText={valuetext}
                    />
                  </Box>
                </Form.Group>
                <Form.Group className="mb-0 px-2" controlId="formBasicEmail">
                  <Form.Label className="d-flex justify-content-between mb-0">
                    <span className="fw-normal fs-6 text-black">{filterRecipe?.CHOCDF[0]} g Carbs</span>
                    <span className="fw-normal fs-6 text-black">
                      {filterRecipe?.CHOCDF[1]}
                      {filterRecipe?.CHOCDF[1] === defaultMaxFilterValues['CHOCDF'] ? '+' : ''} g Carbs
                    </span>
                  </Form.Label>
                  <Box sx={{ width: 300 }}>
                    <Slider
                      getAriaLabel={() => 'range'}
                      sx={{
                        width: 400,
                        height: 8,
                        color: '#F36F27'
                      }}
                      min={0}
                      max={defaultMaxFilterValues['CHOCDF']}
                      name="CHOCDF"
                      value={filterRecipe?.CHOCDF}
                      onChange={FilterRecipeRange}
                      valueLabelDisplay="auto"
                      // getAriaValueText={valuetext}
                    />
                  </Box>
                </Form.Group>
                <Form.Group className="mb-0 px-2" controlId="formBasicEmail">
                  <Form.Label className="d-flex justify-content-between mb-0">
                    <span className="fw-normal fs-6 text-black">{filterRecipe?.FIBTG[0]} g Fiber</span>
                    <span className="fw-normal fs-6 text-black">
                      {filterRecipe?.FIBTG[1]}
                      {filterRecipe?.FIBTG[1] === defaultMaxFilterValues['FIBTG'] ? '+' : ''} g Fiber
                    </span>
                  </Form.Label>
                  <Box sx={{ width: 300 }}>
                    <Slider
                      getAriaLabel={() => 'range'}
                      sx={{
                        width: 400,
                        height: 8,
                        color: '#F36F27'
                      }}
                      min={0}
                      max={defaultMaxFilterValues['FIBTG']}
                      name="FIBTG"
                      value={filterRecipe?.FIBTG}
                      onChange={FilterRecipeRange}
                      valueLabelDisplay="auto"
                      // getAriaValueText={valuetext}
                    />
                  </Box>
                </Form.Group>
                <Form.Group className="mb-0 px-2" controlId="formBasicEmail">
                  <Form.Label className="d-flex justify-content-between mb-0">
                    <span className="fw-normal fs-6 text-black">{filterRecipe?.SUGAR[0]} g Sugar</span>
                    <span className="fw-normal fs-6 text-black">
                      {filterRecipe?.SUGAR[1]}
                      {filterRecipe?.SUGAR[1] === defaultMaxFilterValues['SUGAR'] ? '+' : ''} g Sugar
                    </span>
                  </Form.Label>
                  <Box sx={{ width: 300 }}>
                    <Slider
                      getAriaLabel={() => 'range'}
                      sx={{
                        width: 400,
                        height: 8,
                        color: '#F36F27'
                      }}
                      min={0}
                      max={defaultMaxFilterValues['SUGAR']}
                      name="SUGAR"
                      value={filterRecipe?.SUGAR}
                      onChange={FilterRecipeRange}
                      valueLabelDisplay="auto"
                      // getAriaValueText={valuetext}
                    />
                  </Box>
                </Form.Group>
                <Form.Group className="mb-0 px-2" controlId="formBasicEmail">
                  <Form.Label className="d-flex justify-content-between mb-0">
                    <span className="fw-normal fs-6 text-black">{filterRecipe?.PROCNT[0]} g Protein</span>
                    <span className="fw-normal fs-6 text-black">
                      {filterRecipe?.PROCNT[1]}
                      {filterRecipe?.PROCNT[1] === defaultMaxFilterValues['PROCNT'] ? '+' : ''} g Protein
                    </span>
                  </Form.Label>
                  <Box sx={{ width: 300 }}>
                    <Slider
                      getAriaLabel={() => 'range'}
                      sx={{
                        width: 400,
                        height: 8,
                        color: '#F36F27'
                      }}
                      min={0}
                      max={defaultMaxFilterValues['PROCNT']}
                      name="PROCNT"
                      value={filterRecipe?.PROCNT}
                      onChange={FilterRecipeRange}
                      valueLabelDisplay="auto"
                      // getAriaValueText={valuetext}
                    />
                  </Box>
                </Form.Group>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                <Form.Label className="text-green fw-600 fs-17">Vitamins</Form.Label>
              </Accordion.Header>
              <Accordion.Body>
                <Form.Group className="mb-0 px-2" controlId="formBasicEmail">
                  <Form.Label className="d-flex justify-content-between mb-0">
                    <span className="fw-normal fs-6 text-black">{filterRecipe?.VITA_RAE[0]} Vitamin A</span>
                    <span className="fw-normal fs-6 text-black">
                      {filterRecipe?.VITA_RAE[1]}
                      {filterRecipe?.VITA_RAE[1] === defaultMaxFilterValues['VITA_RAE'] ? '+' : ''} UI Vitamin A
                    </span>
                  </Form.Label>
                  <Box sx={{ width: 300 }}>
                    <Slider
                      getAriaLabel={() => 'range'}
                      sx={{
                        width: 400,
                        height: 8,
                        color: '#F36F27'
                      }}
                      min={0}
                      max={defaultMaxFilterValues['VITA_RAE']}
                      name="VITA_RAE"
                      value={filterRecipe?.VITA_RAE}
                      onChange={FilterRecipeRange}
                      valueLabelDisplay="auto"
                      // getAriaValueText={valuetext}
                    />
                  </Box>
                </Form.Group>
                <Form.Group className="mb-0 px-2" controlId="formBasicEmail">
                  <Form.Label className="d-flex justify-content-between mb-0">
                    <span className="fw-normal fs-6 text-black">{filterRecipe?.VITC[0]} Vitamin C</span>
                    <span className="fw-normal fs-6 text-black">
                      {filterRecipe?.VITC[1]}
                      {filterRecipe?.VITC[1] === defaultMaxFilterValues['VITC'] ? '+' : ''} mg Vitamin C
                    </span>
                  </Form.Label>
                  <Box sx={{ width: 300 }}>
                    <Slider
                      getAriaLabel={() => 'range'}
                      sx={{
                        width: 400,
                        height: 8,
                        color: '#F36F27'
                      }}
                      min={0}
                      max={defaultMaxFilterValues['VITC']}
                      name="VITC"
                      value={filterRecipe?.VITC}
                      onChange={FilterRecipeRange}
                      valueLabelDisplay="auto"
                      // getAriaValueText={valuetext}
                    />
                  </Box>
                </Form.Group>
                <Form.Group className="mb-0 px-2" controlId="formBasicEmail">
                  <Form.Label className="d-flex justify-content-between mb-0">
                    <span className="fw-normal fs-6 text-black">{filterRecipe?.TOCPHA[0]} Vitamin E</span>
                    <span className="fw-normal fs-6 text-black">
                      {filterRecipe?.TOCPHA[1]}
                      {filterRecipe?.TOCPHA[1] === defaultMaxFilterValues['TOCPHA'] ? '+' : ''} mg Vitamin E
                    </span>
                  </Form.Label>
                  <Box sx={{ width: 300 }}>
                    <Slider
                      getAriaLabel={() => 'range'}
                      sx={{
                        width: 400,
                        height: 8,
                        color: '#F36F27'
                      }}
                      min={0}
                      step={0.01}
                      max={defaultMaxFilterValues['TOCPHA']}
                      name="TOCPHA"
                      value={filterRecipe?.TOCPHA}
                      onChange={FilterRecipeRange}
                      valueLabelDisplay="auto"
                      // getAriaValueText={valuetext}
                    />
                  </Box>
                </Form.Group>
                <Form.Group className="mb-0 px-2" controlId="formBasicEmail">
                  <Form.Label className="d-flex justify-content-between mb-0">
                    <span className="fw-normal fs-6 text-black">{filterRecipe?.VITD[0]} Vitamin D</span>
                    <span className="fw-normal fs-6 text-black">
                      {filterRecipe?.VITD[1]}
                      {filterRecipe?.VITD[1] === defaultMaxFilterValues['VITD'] ? '+' : ''} IU Vitamin D
                    </span>
                  </Form.Label>
                  <Box sx={{ width: 300 }}>
                    <Slider
                      getAriaLabel={() => 'range'}
                      sx={{
                        width: 400,
                        height: 8,
                        color: '#F36F27'
                      }}
                      min={0}
                      max={defaultMaxFilterValues['VITD']}
                      name="VITD"
                      value={filterRecipe?.VITD}
                      onChange={FilterRecipeRange}
                      valueLabelDisplay="auto"
                      // getAriaValueText={valuetext}
                    />
                  </Box>
                </Form.Group>
                <Form.Group className="mb-0 px-2" controlId="formBasicEmail">
                  <Form.Label className="d-flex justify-content-between mb-0">
                    <span className="fw-normal fs-6 text-black">{filterRecipe?.VITK1[0]} Vitamin K</span>
                    <span className="fw-normal fs-6 text-black">
                      {filterRecipe?.VITK1[1]}
                      {filterRecipe?.VITK1[1] === defaultMaxFilterValues['VITK1'] ? '+' : ''} µg Vitamin K
                    </span>
                  </Form.Label>
                  <Box sx={{ width: 300 }}>
                    <Slider
                      getAriaLabel={() => 'range'}
                      sx={{
                        width: 400,
                        height: 8,
                        color: '#F36F27'
                      }}
                      min={0}
                      max={defaultMaxFilterValues['VITK1']}
                      name="VITK1"
                      value={filterRecipe?.VITK1}
                      onChange={FilterRecipeRange}
                      valueLabelDisplay="auto"
                      // getAriaValueText={valuetext}
                    />
                  </Box>
                </Form.Group>
                <Form.Group className="mb-0 px-2" controlId="formBasicEmail">
                  <Form.Label className="d-flex justify-content-between mb-0">
                    <span className="fw-normal fs-6 text-black">{filterRecipe?.VITB6A[0]} Vitamin B6</span>
                    <span className="fw-normal fs-6 text-black">
                      {filterRecipe?.VITB6A[1]}
                      {filterRecipe?.VITB6A[1] === defaultMaxFilterValues['VITB6A'] ? '+' : ''} mg Vitamin B6
                    </span>
                  </Form.Label>
                  <Box sx={{ width: 300 }}>
                    <Slider
                      getAriaLabel={() => 'range'}
                      sx={{
                        width: 400,
                        height: 8,
                        color: '#F36F27'
                      }}
                      min={0}
                      step={0.01}
                      max={defaultMaxFilterValues['VITB6A']}
                      name="VITB6A"
                      value={filterRecipe?.VITB6A}
                      onChange={FilterRecipeRange}
                      valueLabelDisplay="auto"
                      // getAriaValueText={valuetext}
                    />
                  </Box>
                </Form.Group>
                <Form.Group className="mb-0 px-2" controlId="formBasicEmail">
                  <Form.Label className="d-flex justify-content-between mb-0">
                    <span className="fw-normal fs-6 text-black">{filterRecipe?.VITB12[0]} Vitamin B12</span>
                    <span className="fw-normal fs-6 text-black">
                      {filterRecipe?.VITB12[1]}
                      {filterRecipe?.VITB12[1] === defaultMaxFilterValues['VITB12'] ? '+' : ''} µg Vitamin B12
                    </span>
                  </Form.Label>
                  <Box sx={{ width: 300 }}>
                    <Slider
                      getAriaLabel={() => 'range'}
                      sx={{
                        width: 400,
                        height: 8,
                        color: '#F36F27'
                      }}
                      min={0}
                      step={0.01}
                      max={defaultMaxFilterValues['VITB12']}
                      name="VITB12"
                      value={filterRecipe?.VITB12}
                      onChange={FilterRecipeRange}
                      valueLabelDisplay="auto"
                      // getAriaValueText={valuetext}
                    />
                  </Box>
                </Form.Group>
                <Form.Group className="mb-0 px-2" controlId="formBasicEmail">
                  <Form.Label className="d-flex justify-content-between mb-0">
                    <span className="fw-normal fs-6 text-black">{filterRecipe?.THIA[0]} Thiamine</span>
                    <span className="fw-normal fs-6 text-black">
                      {filterRecipe?.THIA[1]}
                      {filterRecipe?.THIA[1] === defaultMaxFilterValues['THIA'] ? '+' : ''} mg Thiamine
                    </span>
                  </Form.Label>
                  <Box sx={{ width: 300 }}>
                    <Slider
                      getAriaLabel={() => 'range'}
                      sx={{
                        width: 400,
                        height: 8,
                        color: '#F36F27'
                      }}
                      min={0}
                      step={0.01}
                      max={defaultMaxFilterValues['THIA']}
                      name="THIA"
                      value={filterRecipe?.THIA}
                      onChange={FilterRecipeRange}
                      valueLabelDisplay="auto"
                      // getAriaValueText={valuetext}
                    />
                  </Box>
                </Form.Group>
                <Form.Group className="mb-0 px-2" controlId="formBasicEmail">
                  <Form.Label className="d-flex justify-content-between mb-0">
                    <span className="fw-normal fs-6 text-black">{filterRecipe?.RIBF[0]} Riboflavin</span>
                    <span className="fw-normal fs-6 text-black">
                      {filterRecipe?.RIBF[1]}
                      {filterRecipe?.RIBF[1] === defaultMaxFilterValues['RIBF'] ? '+' : ''} mg Riboflavin
                    </span>
                  </Form.Label>
                  <Box sx={{ width: 300 }}>
                    <Slider
                      getAriaLabel={() => 'range'}
                      sx={{
                        width: 400,
                        height: 8,
                        color: '#F36F27'
                      }}
                      min={0}
                      step={0.01}
                      max={defaultMaxFilterValues['RIBF']}
                      name="RIBF"
                      value={filterRecipe?.RIBF}
                      onChange={FilterRecipeRange}
                      valueLabelDisplay="auto"
                      // getAriaValueText={valuetext}
                    />
                  </Box>
                </Form.Group>
                <Form.Group className="mb-0 px-2" controlId="formBasicEmail">
                  <Form.Label className="d-flex justify-content-between mb-0">
                    <span className="fw-normal fs-6 text-black">{filterRecipe?.NIA[0]} Niacin</span>
                    <span className="fw-normal fs-6 text-black">
                      {filterRecipe?.NIA[1]}
                      {filterRecipe?.NIA[1] === defaultMaxFilterValues['NIA'] ? '+' : ''} mg Niacin
                    </span>
                  </Form.Label>
                  <Box sx={{ width: 300 }}>
                    <Slider
                      getAriaLabel={() => 'range'}
                      sx={{
                        width: 400,
                        height: 8,
                        color: '#F36F27'
                      }}
                      min={0}
                      step={0.01}
                      max={defaultMaxFilterValues['NIA']}
                      name="NIA"
                      value={filterRecipe?.NIA}
                      onChange={FilterRecipeRange}
                      valueLabelDisplay="auto"
                      // getAriaValueText={valuetext}
                    />
                  </Box>
                </Form.Group>
                <Form.Group className="mb-0 px-2" controlId="formBasicEmail">
                  <Form.Label className="d-flex justify-content-between mb-0">
                    <span className="fw-normal fs-6 text-black">{filterRecipe?.FOLFD[0]} Folate</span>
                    <span className="fw-normal fs-6 text-black">
                      {filterRecipe?.FOLFD[1]}
                      {filterRecipe?.FOLFD[1] === defaultMaxFilterValues['FOLFD'] ? '+' : ''} µg Folate
                    </span>
                  </Form.Label>
                  <Box sx={{ width: 300 }}>
                    <Slider
                      getAriaLabel={() => 'range'}
                      sx={{
                        width: 400,
                        height: 8,
                        color: '#F36F27'
                      }}
                      min={0}
                      max={defaultMaxFilterValues['FOLFD']}
                      name="FOLFD"
                      value={filterRecipe?.FOLFD}
                      onChange={FilterRecipeRange}
                      valueLabelDisplay="auto"
                      // getAriaValueText={valuetext}
                    />
                  </Box>
                </Form.Group>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>
                <Form.Label className="text-green fw-600 fs-17">Minerals</Form.Label>
              </Accordion.Header>
              <Accordion.Body>
                <Form.Group className="mb-0 px-2" controlId="formBasicEmail">
                  <Form.Label className="d-flex justify-content-between mb-0">
                    <span className="fw-normal fs-6 text-black">{filterRecipe?.NA[0]} mg Sodium</span>
                    <span className="fw-normal fs-6 text-black">
                      {filterRecipe?.NA[1]}
                      {filterRecipe?.NA[1] === defaultMaxFilterValues['NA'] ? '+' : ''} mg Sodium
                    </span>
                  </Form.Label>
                  <Box sx={{ width: 300 }}>
                    <Slider
                      getAriaLabel={() => 'range'}
                      sx={{
                        width: 400,
                        height: 8,
                        color: '#F36F27'
                      }}
                      min={0}
                      max={defaultMaxFilterValues['NA']}
                      name="NA"
                      value={filterRecipe?.NA}
                      onChange={FilterRecipeRange}
                      valueLabelDisplay="auto"
                      // getAriaValueText={valuetext}
                    />
                  </Box>
                </Form.Group>
                <Form.Group className="mb-0 px-2" controlId="formBasicEmail">
                  <Form.Label className="d-flex justify-content-between mb-0">
                    <span className="fw-normal fs-6 text-black">{filterRecipe?.K[0]} mg Potassium</span>
                    <span className="fw-normal fs-6 text-black">
                      {filterRecipe?.K[1]}
                      {filterRecipe?.K[1] === defaultMaxFilterValues['K'] ? '+' : ''} mg Potassium
                    </span>
                  </Form.Label>
                  <Box sx={{ width: 300 }}>
                    <Slider
                      getAriaLabel={() => 'range'}
                      sx={{
                        width: 400,
                        height: 8,
                        color: '#F36F27'
                      }}
                      min={0}
                      max={defaultMaxFilterValues['K']}
                      name="K"
                      value={filterRecipe?.K}
                      onChange={FilterRecipeRange}
                      valueLabelDisplay="auto"
                      // getAriaValueText={valuetext}
                    />
                  </Box>
                </Form.Group>
                <Form.Group className="mb-0 px-2" controlId="formBasicEmail">
                  <Form.Label className="d-flex justify-content-between mb-0">
                    <span className="fw-normal fs-6 text-black">{filterRecipe?.CA[0]} mg Calcium</span>
                    <span className="fw-normal fs-6 text-black">
                      {filterRecipe?.CA[1]}
                      {filterRecipe?.CA[1] === defaultMaxFilterValues['CA'] ? '+' : ''} mg Calcium
                    </span>
                  </Form.Label>
                  <Box sx={{ width: 300 }}>
                    <Slider
                      getAriaLabel={() => 'range'}
                      sx={{
                        width: 400,
                        height: 8,
                        color: '#F36F27'
                      }}
                      min={0}
                      max={defaultMaxFilterValues['CA']}
                      name="CA"
                      value={filterRecipe?.CA}
                      onChange={FilterRecipeRange}
                      valueLabelDisplay="auto"
                      // getAriaValueText={valuetext}
                    />
                  </Box>
                </Form.Group>
                <Form.Group className="mb-0 px-2" controlId="formBasicEmail">
                  <Form.Label className="d-flex justify-content-between mb-0">
                    <span className="fw-normal fs-6 text-black">{filterRecipe?.FE[0]} mg Iron</span>
                    <span className="fw-normal fs-6 text-black">
                      {filterRecipe?.FE[1]}
                      {filterRecipe?.FE[1] === defaultMaxFilterValues['FE'] ? '+' : ''} mg Iron
                    </span>
                  </Form.Label>
                  <Box sx={{ width: 300 }}>
                    <Slider
                      getAriaLabel={() => 'range'}
                      sx={{
                        width: 400,
                        height: 8,
                        color: '#F36F27'
                      }}
                      min={0}
                      step={0.01}
                      max={defaultMaxFilterValues['FE']}
                      name="FE"
                      value={filterRecipe?.FE}
                      onChange={FilterRecipeRange}
                      valueLabelDisplay="auto"
                      // getAriaValueText={valuetext}
                    />
                  </Box>
                </Form.Group>
                <Form.Group className="mb-0 px-2" controlId="formBasicEmail">
                  <Form.Label className="d-flex justify-content-between mb-0">
                    <span className="fw-normal fs-6 text-black">{filterRecipe?.P[0]} mg Phosphorus</span>
                    <span className="fw-normal fs-6 text-black">
                      {filterRecipe?.P[1]}
                      {filterRecipe?.P[1] === defaultMaxFilterValues['P'] ? '+' : ''} mg Phosphorus
                    </span>
                  </Form.Label>
                  <Box sx={{ width: 300 }}>
                    <Slider
                      getAriaLabel={() => 'range'}
                      sx={{
                        width: 400,
                        height: 8,
                        color: '#F36F27'
                      }}
                      min={0}
                      max={defaultMaxFilterValues['P']}
                      name="P"
                      value={filterRecipe?.P}
                      onChange={FilterRecipeRange}
                      valueLabelDisplay="auto"
                      // getAriaValueText={valuetext}
                    />
                  </Box>
                </Form.Group>
                <Form.Group className="mb-0 px-2" controlId="formBasicEmail">
                  <Form.Label className="d-flex justify-content-between mb-0">
                    <span className="fw-normal fs-6 text-black">{filterRecipe?.MG[0]} mg Magnesium</span>
                    <span className="fw-normal fs-6 text-black">
                      {filterRecipe?.MG[1]}
                      {filterRecipe?.MG[1] === defaultMaxFilterValues['MG'] ? '+' : ''} mg Magnesium
                    </span>
                  </Form.Label>
                  <Box sx={{ width: 300 }}>
                    <Slider
                      getAriaLabel={() => 'range'}
                      sx={{
                        width: 400,
                        height: 8,
                        color: '#F36F27'
                      }}
                      min={0}
                      max={defaultMaxFilterValues['MG']}
                      name="MG"
                      value={filterRecipe?.MG}
                      onChange={FilterRecipeRange}
                      valueLabelDisplay="auto"
                      // getAriaValueText={valuetext}
                    />
                  </Box>
                </Form.Group>
                <Form.Group className="mb-0 px-2" controlId="formBasicEmail">
                  <Form.Label className="d-flex justify-content-between mb-0">
                    <span className="fw-normal fs-6 text-black">{filterRecipe?.ZN[0]} mg Zinc</span>
                    <span className="fw-normal fs-6 text-black">
                      {filterRecipe?.ZN[1]}
                      {filterRecipe?.ZN[1] === defaultMaxFilterValues['ZN'] ? '+' : ''} mg Zinc
                    </span>
                  </Form.Label>
                  <Box sx={{ width: 300 }}>
                    <Slider
                      getAriaLabel={() => 'range'}
                      sx={{
                        width: 400,
                        height: 8,
                        color: '#F36F27'
                      }}
                      min={0}
                      step={0.01}
                      max={defaultMaxFilterValues['ZN']}
                      name="ZN"
                      value={filterRecipe?.ZN}
                      onChange={FilterRecipeRange}
                      valueLabelDisplay="auto"
                      // getAriaValueText={valuetext}
                    />
                  </Box>
                </Form.Group>
                <Form.Group className="mb-0 px-2" controlId="formBasicEmail">
                  <Form.Label className="d-flex justify-content-between mb-0">
                    <span className="fw-normal fs-6 text-black">{filterRecipe?.Selenium[0]} µg Selenium</span>
                    <span className="fw-normal fs-6 text-black">
                      {filterRecipe?.Selenium[1]}
                      {filterRecipe?.Selenium[1] === defaultMaxFilterValues['Selenium'] ? '+' : ''} µg Selenium
                    </span>
                  </Form.Label>
                  <Box sx={{ width: 300 }}>
                    <Slider
                      getAriaLabel={() => 'range'}
                      sx={{
                        width: 400,
                        height: 8,
                        color: '#F36F27'
                      }}
                      min={0}
                      max={defaultMaxFilterValues['Selenium']}
                      name="Selenium"
                      value={filterRecipe?.Selenium}
                      onChange={FilterRecipeRange}
                      valueLabelDisplay="auto"
                      // getAriaValueText={valuetext}
                    />
                  </Box>
                </Form.Group>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </Tab>
    </Tabs>
  )
}
