import React, { useState, useEffect } from 'react';
import { Button, ButtonGroup, DropdownButton, Dropdown } from 'react-bootstrap';
import SladPic from '../assets/images/greek-salad-food 2.png';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

export default function UserRecipeCard(props) {
	const [favStatus, setFavStatus] = useState(false);
	const [loading, setLoading] = useState(false);
    
    const recipeRecordId = props?.data?._id;
    const userAllFavRecipes = props?.favRecipes;
    useEffect(() => { 
        setFavStatus(props?.favRecipes.indexOf(recipeRecordId) > -1 ? true : false);     
    }, []);
    
    const handleFavButton = async (recipeId) => {
    	setLoading(true);
    	try {
    		let userdata = JSON.parse(localStorage.getItem('userdata'));
            
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/favtry/addFavourire`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${userdata?.token}`
                },
                body: JSON.stringify( { recipeId: recipeId  })
            }).then(res => res.json())
            
            if (response?.status) {
                console.log(response.message);
            	toast.success(response.message)
            	setFavStatus(true);
            	setLoading(false);
            } else {
            	toast.error(response.message)
            	setLoading(false);
            }
    	} catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }
    	
    	
    }
    
    const handleRemoveFavButton = async (recipeId) => {
    	setLoading(true);
    	try {
	    	const userdata = JSON.parse(localStorage.getItem('userdata'));
	        const userId = userdata?._id;
	        
	        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/user/deleteFavouriteRecipe`, {
	            method: 'POST',
	            headers: {
	                'Content-Type': 'application/json'
	            },
	            body: JSON.stringify({  userId : userId , recipeId : recipeId })
	        }).then(res => res.json())
	        if (response?.status) {
	        	toast.success(response.message)
	        	setFavStatus(false);
	        	setLoading(false);
	        } else {
	        	toast.error(response.message)
	        	setLoading(false);
	        }
    	} catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }
    }
    
    return (
        <div className='recipe-box h-100 user-recipe-box fav-recipe-box'>
            <div className='recipe-card-img'>
	            { (favStatus === true ) 
	            		?  <button type='button' className='icon-div active'  onClick={() => handleRemoveFavButton(props?.data?._id)} >
	            				<svg xmlns="http://www.w3.org/2000/svg" className='heart-icon-svg' width="28" height="28" viewBox="0 0 28 28" fill="none">
	            					<path d="M19.0936 4.12566C17.0466 4.12566 15.2144 5.12089 14.0722 6.64768C12.9299 5.12089 11.0978 4.12566 9.05073 4.12566C5.57871 4.12566 2.76263 6.95304 2.76263 10.4477C2.76263 11.7935 2.97752 13.0376 3.35073 14.1911C5.13763 19.8459 10.6454 23.2274 13.371 24.1548C13.7555 24.2905 14.3888 24.2905 14.7733 24.1548C17.4989 23.2274 23.0067 19.8459 24.7936 14.1911C25.1668 13.0376 25.3817 11.7935 25.3817 10.4477C25.3817 6.95304 22.5656 4.12566 19.0936 4.12566Z" fill="#FF1919"/>
	            				</svg>
	            			</button> 
	            		: <button type='button' className='icon-div' onClick={() => handleFavButton(props?.data?._id)}>
	            				<svg xmlns="http://www.w3.org/2000/svg" className='heart-icon-svg' width="28" height="28" viewBox="0 0 28 28" fill="none">
	            					<path d="M19.0936 4.12566C17.0466 4.12566 15.2144 5.12089 14.0722 6.64768C12.9299 5.12089 11.0978 4.12566 9.05073 4.12566C5.57871 4.12566 2.76263 6.95304 2.76263 10.4477C2.76263 11.7935 2.97752 13.0376 3.35073 14.1911C5.13763 19.8459 10.6454 23.2274 13.371 24.1548C13.7555 24.2905 14.3888 24.2905 14.7733 24.1548C17.4989 23.2274 23.0067 19.8459 24.7936 14.1911C25.1668 13.0376 25.3817 11.7935 25.3817 10.4477C25.3817 6.95304 22.5656 4.12566 19.0936 4.12566Z" fill="#FF1919"/>
	            				</svg>
	            		 </button>
	            	
	            }
            	<button type='button' className='icon-div-share icon-div' onClick={() => {props?.handleShareButtonAction(props?.data?._id)}}>
                    <svg xmlns="http://www.w3.org/2000/svg" className='share-icon-svg bi bi-share' width="20" height="20" fill="currentColor" viewBox="0 0 16 16">
                        <path d="M13.5 1a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.5 2.5 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5m-8.5 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3m11 5.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3" fill="#FF1919"/>
                    </svg>
	            </button>
            	<img src={props?.data?.image} className='w-100 h-100 object-fit-cover' />
            </div>
            <div className='recipe-card-body user-recipe-card'>
                <div className='d-flex justify-content-between align-items-center gx-2'>
                    <Link
                        to={`/view-recipe/${props?.data?._id}`}
                        className='text-dark mb-2 fw-600 fs-5 text-decoration-none d-block'
                        style={{ lineHeight: '1.5' }}
                    >
                        {props?.data?.label}
                    </Link>
                    <div>
                        <DropdownButton className="meal-plan-item-action d-flex justify-content-end" variant="outline-light" title={
                            <svg xmlns="http://www.w3.org/2000/svg" width="13" height="3" viewBox="0 0 13 3">
                            <circle cx="1.5" cy="1.5" r="1.5"></circle>
                            <circle cx="11.5" cy="1.5" r="1.5"></circle>
                            <circle cx="6.5" cy="1.5" r="1.5"></circle>
                            </svg>} align="end">
                            <Dropdown.Item tag={Link} href={`/edit-recipe/${props?.data?._id}`}>Edit</Dropdown.Item>
                            {/* <Dropdown.Item as="link" to={`/edit-recipe/${props?.data?._id}`}>Edit</Dropdown.Item> */}
                            <Dropdown.Item as="button" onClick={() => { props?.handleDeleteAction(props?.data?._id); }}>Delete</Dropdown.Item>
                        </DropdownButton>
                    </div>
                </div>

                <div>
                    <div className='d-flex mb-2 flex-wrap flex-column'>
                        <p className='text-custom-grey fw-normal fs-17 me-3 mb-2'><svg className='me-2' xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                            <path d="M10.7161 0C5.17345 0 0.666992 4.57565 0.666992 10.2034C0.666992 15.8312 5.17345 20.4069 10.7161 20.4069C16.2588 20.4069 20.7653 15.8312 20.7653 10.2034C20.7653 4.57565 16.2588 0 10.7161 0ZM15.2226 12.0335H9.36447V4.48397H11.167V9.97443H15.2228L15.2226 12.0335Z" fill="#86C52F" />
                        </svg>{props?.data?.totalTime || 0} minutes</p>
                        <p className='text-custom-grey fw-normal fs-17 me-3 mb-2'>
                            <svg className='me-1' xmlns="http://www.w3.org/2000/svg" width="20" height="19" viewBox="0 0 20 19" fill="none">
                                <path fillRule="evenodd" clipRule="evenodd" d="M18.8859 7.22865C18.6167 6.90192 18.1382 6.8564 17.8142 7.12979L13.4622 10.8119H2.32854C1.90861 10.8119 1.56787 11.1579 1.56787 11.5841V15.1414C1.56787 17.0126 3.06788 18.5348 4.91074 18.5348H14.9592C16.8021 18.5348 18.3012 17.0126 18.3012 15.1414V11.5841C18.3012 11.1579 17.9613 10.8119 17.5407 10.8119H15.8392L18.7888 8.31586C19.1113 8.04328 19.1555 7.55603 18.8862 7.2285" fill="#FF964A" />
                                <path fillRule="evenodd" clipRule="evenodd" d="M6.13206 8.49536C6.32824 8.49536 6.52296 8.41819 6.66978 8.26911L9.71211 5.17992C9.98601 4.90195 10.0111 4.4586 9.76849 4.15127L6.72602 0.289881C6.59829 0.12854 6.41193 0.0249757 6.20888 0.00410004C6.00727 -0.0174479 5.80114 0.0466581 5.64451 0.179445L1.08091 4.04084C0.896935 4.19679 0.795735 4.43167 0.808733 4.6749C0.822394 4.91812 0.947073 5.14141 1.14643 5.27648L5.71003 8.36567C5.83856 8.45294 5.98618 8.49536 6.13223 8.49536" fill="#FF964A" />
                                <path fillRule="evenodd" clipRule="evenodd" d="M10.6956 7.72327C11.1154 7.72327 11.4561 7.37729 11.4561 6.95092C11.4561 6.52468 11.1154 6.17871 10.6956 6.17871C10.2758 6.17871 9.93506 6.52468 9.93506 6.95092C9.93506 7.37729 10.2758 7.72327 10.6956 7.72327Z" fill="#FF964A" />
                                <path fillRule="evenodd" clipRule="evenodd" d="M9.17411 8.49536C8.75432 8.49536 8.41357 8.84134 8.41357 9.26771C8.41357 9.69395 8.75432 10.0399 9.17411 10.0399C9.59403 10.0399 9.93478 9.69395 9.93478 9.26771C9.93478 8.84134 9.59403 8.49536 9.17411 8.49536Z" fill="#FF964A" />
                            </svg>
                            {props?.data?.ingredients?.length} ingredients
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}
