import React, { useState } from 'react'
import Sidebar from '../components/Sidebar'
import TopBar from '../components/TopBar'
import {
  Button,
  Col,
  Form,
  InputGroup,
  Modal,
  ModalTitle,
  Row,
  Table,
  Pagination,
  Dropdown,
  Tab,
  Tabs,
  DropdownButton,
  ModalHeader
} from 'react-bootstrap'
import { Link } from 'react-router-dom'
import uploadIcon from '../../src/assets/images/upload-icon.png'
import plusIcon from '../../src/assets/images/plus-icon.png'
import addRecipeImg from '../../src/assets/images/add-recipe.jpg'

export default function DesignAddRecipe() {
  const [show, setShow] = useState(false)
  const [showTwo, setShowTwo] = useState(false)

  const handleClose = () => setShow(false)
  const handleCloseTwo = () => setShowTwo(false)
  const handleShow = () => setShow(true)
  const handleShowTwo = () => setShowTwo(true)

  return (
    <div className="main-parent add-recipe-page">
      <Modal centered show={show} size={'lg'} className="addingredients-modal" onHide={handleClose} backdrop="static" keyboard={false}>
        <ModalHeader className="px-0 pt-0">
          <h5 className="modal-title">Add Ingredients</h5>
          <button type="button" className="btn-close" onClick={handleClose} aria-label="Close"></button>
        </ModalHeader>
        <Modal.Body className="px-0">
          <Tabs defaultActiveKey="Details" id="justify-tab-example-2" className="addingredients-tabs-sec">
            <Tab eventKey="Details" title="Details" className="details-tab">
              <Row className="form-row">
                <Col sm={6}>
                  <div className="form-group">
                    <label htmlFor="title" className="label-tag">
                      Name of Ingredient{' '}
                    </label>
                    <input type="text" className="input-tag" name="title" placeholder="Type Here" />
                  </div>
                </Col>
                <Col sm={6}>
                  <div className="form-group">
                    <label htmlFor="time" className="label-tag">
                      Nutrition Form{' '}
                    </label>
                    <input type="text" className="input-tag" name="time" placeholder="Un available" />
                  </div>
                </Col>
                <Col sm={3}>
                  <div className="form-group">
                    <label htmlFor="quantity" className="label-tag">
                      Quantity
                    </label>
                    <select name="quantity" className="input-tag">
                      <option value=""></option>
                      <option value="lorem">lorem</option>
                    </select>
                  </div>
                </Col>
                <Col sm={3}>
                  <div className="form-group">
                    <label htmlFor="unit_of_measure" className="label-tag">
                      Unit of Measure
                    </label>
                    <select name="unit_of_measure" className="input-tag">
                      <option value=""></option>
                      <option value="lorem">lorem</option>
                    </select>
                  </div>
                </Col>
                <Col sm={6}>
                  <div className="form-group">
                    <label htmlFor="category" className="label-tag">
                      Category
                    </label>
                    <select name="category" className="input-tag">
                      <option value=""></option>
                      <option value="lorem">lorem</option>
                    </select>
                  </div>
                </Col>
                <Col sm={12}>
                  <div className="form-group">
                    <label htmlFor="preparation_notes" className="label-tag">
                      Preparation Notes
                    </label>
                    <textarea rows="4" className="input-tag h-auto" name="preparation_notes" placeholder="Type Here"></textarea>
                  </div>
                </Col>
              </Row>
              <div className="btn-div">
                <Link to={void 0} className="save-btn">
                  Add Ingredient
                </Link>
              </div>
            </Tab>
            <Tab eventKey="Nutrients" title="Nutrients" className="nutrients-tab">
              <div className="inner-card">
                <label className="label-tag amount-tag">Amount Per Serving</label>
                <div className="list-div">
                  <div className="list yellow-circle">
                    <span className="name">Calories</span> <span className="gm">87</span> <span className="perc text-end">0%</span>
                  </div>
                  <div className="list red-circle">
                    <span className="name">Carbohydrates</span> <span className="gm">15.3 g</span> <span className="perc text-end">0%</span>
                  </div>
                  <div className="list blue-circle">
                    <span className="name">Fat</span> <span className="gm">3.8 g</span> <span className="perc text-end">0%</span>
                  </div>
                  <div className="list skyblue-circle">
                    <span className="name">Fiber</span> <span className="gm">3.8 g</span> <span className="perc text-end">0%</span>
                  </div>
                  <div className="list light-circle">
                    <span className="name">Sugar</span> <span className="gm">0.3 g</span> <span className="perc text-end">0%</span>
                  </div>
                  <div className="list light-circle">
                    <span className="name">Proteins</span> <span className="gm">520 mg</span> <span className="perc text-end">0%</span>
                  </div>
                  <div className="list light-circle">
                    <span className="name">Cholesterol</span> <span className="gm">520 mg</span> <span className="perc text-end">0%</span>
                  </div>
                  <div className="list light-circle">
                    <span className="name">Sodium</span> <span className="gm">520 mg</span> <span className="perc text-end">0%</span>
                  </div>
                  <div className="list light-circle">
                    <span className="name">Vitamin A</span> <span className="gm">520 mg</span> <span className="perc text-end">0%</span>
                  </div>
                  <div className="list light-circle">
                    <span className="name">Vitamin C</span> <span className="gm">520 mg</span> <span className="perc text-end">0%</span>
                  </div>
                  <div className="list light-circle">
                    <span className="name">Calcium</span> <span className="gm">520 mg</span> <span className="perc text-end">0%</span>
                  </div>
                </div>
                <div className="total-div">
                  <span className="daily-value">% Daily Value = </span>
                  <span className="output-value">22,000 Calories Diet</span>
                </div>
              </div>
            </Tab>
          </Tabs>
        </Modal.Body>
      </Modal>

      <Modal
        centered
        show={showTwo}
        size={'lg'}
        className="addingredients-modal adddirections-modal"
        onHide={handleCloseTwo}
        backdrop="static"
        keyboard={false}
      >
        <ModalHeader className="px-0 pt-0">
          <h5 className="modal-title">Add Directions</h5>
          <button type="button" className="btn-close" onClick={handleCloseTwo} aria-label="Close"></button>
        </ModalHeader>
        <Modal.Body className="px-0">
          <Row className="form-row">
            <Col sm={12}>
              <div className="tag-sec">
                <label htmlFor="add_tags" className="label-tag">
                  Add tags
                </label>
                <div className="tag-bg">
                  <div className="tag-div">
                    <div className="tag">Medium Bowl</div>
                    <div className="tag">Loaf Pan</div>
                    <div className="tag">Large Bowl</div>
                    <div className="tag">Parchment paper</div>
                  </div>
                </div>
              </div>
            </Col>
            <Col sm={12}>
              <div className="form-group">
                <label htmlFor="preparation_notes" className="label-tag">
                  Add Directions
                </label>
                <textarea rows="4" className="input-tag h-auto" name="preparation_notes" placeholder="Type Here"></textarea>
              </div>
            </Col>
          </Row>
          <div className="btn-div">
            <Link to={void 0} className="save-btn">
              Add Directions
            </Link>
          </div>
        </Modal.Body>
      </Modal>

      <div className="p-xl-5 p-3">
        <div className="heading-div">
          <h4 className="heading-title">Import File</h4>
        </div>

        <div className="import-from-web">
          <div className="container">
            <div className="inner-content">
              <input type="file" id="file" className="inner-file" />
              <label htmlFor="file" className="img-div">
                <img src={uploadIcon} alt="Upload Icon" className="upload-icon" />
                <h4 className="import-text">Import From Web</h4>
              </label>
            </div>
          </div>
        </div>

        <div className="heading-div">
          <h4 className="heading-title">Add Your Recipe</h4>
        </div>

        <div className="add-recipe-inner">
          <div className="inner-content">
            <Row>
              <Col md={4}>
                <div className="add-img-div">
                  <input type="file" id="image-upload" className="inner-file" />
                  <label htmlFor="image-upload" className="img-div">
                    <img src={plusIcon} alt="Upload Icon" className="upload-icon" />
                    <h4 className="add-text">Add Image</h4>
                  </label>
                </div>
              </Col>
              <Col md={8}>
                <div className="img-row">
                  <div className="inner-img">
                    <img src={addRecipeImg} className="add-recipe-img" />
                    <div class="round">
                      <input type="checkbox" id="checkbox" name="img_check" />
                      <label for="checkbox"></label>
                    </div>
                  </div>
                  <div className="inner-img">
                    <img src={addRecipeImg} className="add-recipe-img" />
                    <div class="round">
                      <input type="checkbox" id="checkbox1" name="img_check" />
                      <label for="checkbox1"></label>
                    </div>
                  </div>
                  <div className="inner-img">
                    <img src={addRecipeImg} className="add-recipe-img" />
                    <div class="round">
                      <input type="checkbox" id="checkbox2" name="img_check" />
                      <label for="checkbox2"></label>
                    </div>
                  </div>
                  <div className="inner-img">
                    <img src={addRecipeImg} className="add-recipe-img" />
                    <div class="round">
                      <input type="checkbox" id="checkbox3" name="img_check" />
                      <label for="checkbox3"></label>
                    </div>
                  </div>
                  <div className="inner-img">
                    <img src={addRecipeImg} className="add-recipe-img" />
                    <div class="round">
                      <input type="checkbox" id="checkbox4" name="img_check" />
                      <label for="checkbox4"></label>
                    </div>
                  </div>
                  <div className="inner-img">
                    <img src={addRecipeImg} className="add-recipe-img" />
                    <div class="round">
                      <input type="checkbox" id="checkbox5" name="img_check" />
                      <label for="checkbox5"></label>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <div className="btns">
              <Link to={void 0} className="green-outline-btn">
                Delete Image
              </Link>
              <Link to={void 0} className="green-btn">
                Save Images
              </Link>
            </div>
          </div>
        </div>

        <div className="form-tag-div">
          <Row>
            <Col md={5}>
              <Row className="form-row">
                <Col sm={12}>
                  <div className="form-group">
                    <label htmlFor="title" className="label-tag">
                      Title
                    </label>
                    <input type="text" className="input-tag" name="title" placeholder="Title" />
                  </div>
                </Col>
                <Col sm={6}>
                  <div className="form-group">
                    <label htmlFor="time" className="label-tag">
                      Time
                    </label>
                    <input type="text" className="input-tag" name="time" placeholder="Time" />
                  </div>
                </Col>
                <Col sm={6}>
                  <div className="form-group">
                    <label htmlFor="servings" className="label-tag">
                      Servings
                    </label>
                    <input type="text" className="input-tag" name="servings" placeholder="Servings" />
                  </div>
                </Col>
              </Row>
            </Col>
            <Col md={7}>
              <div className="tag-sec">
                <label htmlFor="add_tags" className="label-tag">
                  Add tags
                </label>
                <div className="tag-bg">
                  <div className="tag-div">
                    <div className="tag">Medium Bowl</div>
                    <div className="tag">Loaf Pan</div>
                    <div className="tag">Large Bowl</div>
                    <div className="tag">Parchment paper</div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>

        <div className="bottom-sec">
          <Row>
            <Col md={6}>
              <Row className="bottom-tab-row">
                <Col sm={12}>
                  <div className="ingredients-div" onClick={handleShow}>
                    <label htmlFor="ingredients" className="label-tag">
                      Ingredients
                    </label>
                    <label htmlFor="ingredients-upload" className="add-div">
                      {/* <input type="file" id="ingredients-upload" className='inner-file' /> */}
                      <div className="inner-content">
                        <h5 className="sub-text">No Ingredient Found</h5>
                        <div className="add-icon-div">
                          <img src={plusIcon} alt="Plus Icon" className="plus-icon" />
                          <h4 className="add-text">Add Ingredients</h4>
                        </div>
                      </div>
                    </label>
                  </div>
                </Col>
                <Col sm={12}>
                  <div className="tabs-div">
                    <Tabs defaultActiveKey="Directions" id="justify-tab-example-2" className="nutrition-tabs-sec">
                      <Tab eventKey="Directions" title="Directions">
                        <div className="directions-div" onClick={handleShowTwo}>
                          <label htmlFor="directions-upload" className="add-div">
                            {/* <input type="file" id="directions-upload" className='inner-file' /> */}
                            <div className="inner-content">
                              <h5 className="sub-text">No Directions Found</h5>
                              <div className="add-icon-div">
                                <img src={plusIcon} alt="Plus Icon" className="plus-icon" />
                                <h4 className="add-text">Add Ingredients</h4>
                              </div>
                            </div>
                          </label>
                        </div>
                      </Tab>
                      <Tab eventKey="Notes" title="Notes">
                        <div className="notes-div">
                          <label htmlFor="notes-upload" className="add-div">
                            <input type="file" id="notes-upload" className="inner-file" />
                            <div className="inner-content">
                              <h5 className="sub-text">No Notes Found</h5>
                              <div className="add-icon-div">
                                <img src={plusIcon} alt="Plus Icon" className="plus-icon" />
                                <h4 className="add-text">Add Ingredients</h4>
                              </div>
                            </div>
                          </label>
                        </div>
                      </Tab>
                    </Tabs>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col md={6}>
              <div className="nutrition-div">
                <label htmlFor="nutrition" className="label-tag">
                  Nutrition
                </label>
                <div className="inner-card">
                  <label className="label-tag amount-tag">Amount Per Serving</label>
                  <div className="list-div">
                    <div className="list yellow-circle">
                      <span className="name">Calories</span> <span className="gm">87</span> <span className="perc text-end">0%</span>
                    </div>
                    <div className="list red-circle">
                      <span className="name">Carbohydrates</span> <span className="gm">15.3 g</span> <span className="perc text-end">0%</span>
                    </div>
                    <div className="list blue-circle">
                      <span className="name">Fat</span> <span className="gm">3.8 g</span> <span className="perc text-end">0%</span>
                    </div>
                    <div className="list skyblue-circle">
                      <span className="name">Fiber</span> <span className="gm">3.8 g</span> <span className="perc text-end">0%</span>
                    </div>
                    <div className="list light-circle">
                      <span className="name">Sugar</span> <span className="gm">0.3 g</span> <span className="perc text-end">0%</span>
                    </div>
                    <div className="list light-circle">
                      <span className="name">Proteins</span> <span className="gm">520 mg</span> <span className="perc text-end">0%</span>
                    </div>
                    <div className="list light-circle">
                      <span className="name">Cholesterol</span> <span className="gm">520 mg</span> <span className="perc text-end">0%</span>
                    </div>
                    <div className="list light-circle">
                      <span className="name">Sodium</span> <span className="gm">520 mg</span> <span className="perc text-end">0%</span>
                    </div>
                    <div className="list light-circle">
                      <span className="name">Vitamin A</span> <span className="gm">520 mg</span> <span className="perc text-end">0%</span>
                    </div>
                    <div className="list light-circle">
                      <span className="name">Vitamin C</span> <span className="gm">520 mg</span> <span className="perc text-end">0%</span>
                    </div>
                    <div className="list light-circle">
                      <span className="name">Calcium</span> <span className="gm">520 mg</span> <span className="perc text-end">0%</span>
                    </div>
                  </div>
                  <div className="total-div">
                    <span className="daily-value">% Daily Value = </span>
                    <span className="output-value">22,000 Calories Diet</span>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>

        <div className="btn-div">
          <Link to={void 0} className="save-btn">
            Save Recipie
          </Link>
        </div>
      </div>
    </div>
  )
}
