import React, { useState, useEffect, Fragment } from 'react'
import { Button, Col, Row, Modal, ButtonGroup } from 'react-bootstrap'
import UserRecipeCard from '../User_components/UserRecipeCard'
import { useAuth } from '../context/AuthProvider'
import { useNavigate } from 'react-router-dom'
import { TailSpin } from 'react-loader-spinner'
import InfiniteScroll from 'react-infinite-scroll-component'
import RecipeFilter from '../User_components/RecipeFilter'
import * as FilterSelectionList from '../User_components/FilterSelectionList'
import { ToastContainer } from 'react-toastify'
import '../assets/css/UserRecipe.css'

export default function UserRecipe() {
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()
  const { user, isAuthenticated } = useAuth()
  const initialFilterValues = FilterSelectionList.initialFilterValues
  const [showFilterModal, setShowFilterModal] = useState(false)
  const [recipeData, setRecipeData] = useState([])
  const [filterRecipe, setFilterRecipe] = useState(initialFilterValues)
  const [dataFound, setDataFound] = useState(true)
  const [totalRecords, setTotalRecords] = useState(0)
  const [nextPageParam, setNextPageParam] = useState(null)
  const [recipeGridStyle, setRecipeGridStyle] = useState('single-h')

  const [favouriteRecipeIds, setFavouriteRecipeIds] = useState([])

  const fetchFavouriteRecipeData = async () => {
    setLoading(true)
    try {
      const userdata = JSON.parse(localStorage.getItem('userdata'))
      const token = userdata.token

      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/favtry/getallMyFavourire`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      })

      if (response.ok) {
        const data = await response.json()
        setLoading(false)
        const uniqueFavRecipeIds = []
        data.data.map((recipe) => {
          uniqueFavRecipeIds.push(recipe?.recipeId)
        })
        setFavouriteRecipeIds(uniqueFavRecipeIds)
      }
    } catch (error) {
      console.error('Error fetching data:', error)
    } finally {
      setLoading(false)
    }
  }

  const fetchRecipes = async () => {
    const searchParams = {}
    searchParams.filter = FilterSelectionList.createFilterRecipeString(filterRecipe)

    if (nextPageParam) searchParams.filter = searchParams.filter + '&_cont=' + nextPageParam

    setLoading(true)
    const getRecipeUrl = `${process.env.REACT_APP_BASE_URL}/admin/recipes`

    try {
      const response = await fetch(getRecipeUrl, {
        body: JSON.stringify(searchParams),
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      }).then((res) => res.json())
      if (response.status) {
        setNextPageParam(response?.data?.next)
        setTotalRecords(response?.data?.count)

        if (nextPageParam) {
          setRecipeData([...recipeData, ...response?.data?.hits])
        } else {
          setRecipeData(response?.data?.hits)
        }

        setDataFound(true)
      } else {
        setRecipeData([])
      }
    } catch (error) {
      console.error('Error fetching recipes: ', error)
      setDataFound(false)
      setRecipeData([])
      setLoading(false)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (isAuthenticated) {
      fetchFavouriteRecipeData()
      fetchRecipes()
    } else {
      navigate('/')
    }
  }, [user, filterRecipe])

  return (
    <Fragment>
      <ToastContainer />
      {loading && (
        <div className="loader-overlay">
          <TailSpin height={80} width={80} color="#4fa94d" ariaLabel="tail-spin-loading" radius={1} visible={true} />
        </div>
      )}
      <div className="recipe-card-header bg-white py-3 px-4 my-3 rounded-4 d-flex flex-wrap justify-content-between gap-2">
        <div className="recipe-card-title">
          <h5 className="text-dark mb-0 fw-600 fs-5">View Recipes</h5>
          <p className="text-custom-grey fw-600 fs-17 ps-2 mb-0">{totalRecords > 1 ? totalRecords + ' Recipes' : totalRecords + ' Recipe'}</p>
        </div>
        <ButtonGroup aria-label="First group">
          <Button className="button-group " onClick={() => setRecipeGridStyle('single-h')}>
            {/*<i class="fa fa-solid fa-grip-horizontal"></i>*/}
            <i class="fa fa-solid fa-stop"></i>
          </Button>
          <Button className="button-group" onClick={() => setRecipeGridStyle('double-h')}>
            <i class="fa fa-solid fa-grip-vertical"></i>
          </Button>
          <Button className="button-group" onClick={() => setRecipeGridStyle('single-v')}>
            <i class="fa fa-light fa-list"></i>
          </Button>
        </ButtonGroup>
      </div>
      <InfiniteScroll dataLength={recipeData.length} next={fetchRecipes} hasMore={recipeData.length !== totalRecords} height={800}>
        <div className={`recipe-grid ${recipeGridStyle}`}>
          {dataFound ? (
            recipeData.map((item) => <UserRecipeCard data={item} favRecipes={favouriteRecipeIds} />)
          ) : (
            <>
              {' '}
              <p>No user recipe data found</p>
            </>
          )}
        </div>
      </InfiniteScroll>
      <Button className="btn-filter" onClick={() => setShowFilterModal(true)}>
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 17 18" fill="#FFFFFF">
          <path d="M0.918945 0H16.7566V2.56205H0.918945V0Z" fill="#FFFFFF" />
          <path
            d="M9.30334 10.7136H6.74145V13.9742L10.9338 17.2348L10.9336 10.2476L15.3355 3.74951H2.33936L6.50838 9.78179H9.30322C9.55942 9.78179 9.76899 10.038 9.76899 10.2942C9.76899 10.5504 9.55941 10.7133 9.30322 10.7133L9.30334 10.7136Z"
            fill="#FFFFFF"
          />
        </svg>
      </Button>
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showFilterModal}
        size="md"
        onHide={() => {
          setShowFilterModal(false)
        }}
      >
        <Modal.Header className="text-center border-0" closeButton>
          <span></span>
          <Modal.Title className="">Filter Recipes</Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-3 ">
          <div className="profile-tab">
            <RecipeFilter filterRecipe={filterRecipe} updateFilterRecipe={setFilterRecipe} updateNextPageLink={setNextPageParam} />
          </div>
        </Modal.Body>
      </Modal>
    </Fragment>
  )
}
