import React from 'react'
import { Button } from 'react-bootstrap'
import moment from 'moment'

export default function MealData(props) {
  const nutritionDate = moment(props?.date).format('YYYY-MM-DD')
  const nutritionData = props?.nutrition
  return (
    <div>
      <span className="d-block border text-center border-bottom-0 text-green rounded-right rounded-top p-1 fw-600">
        {props?.date?.format('MMMM D, YYYY')}
      </span>
      <div className=" p-1 meal-data-list rounded-bottom rounded-left">
        <div className="d-flex gap-1 align-items-center justify-content-between">
          <p className="text-dark mb-0 fw-600">
            <span className="grey-dot" style={{ background: '#FFD700' }}></span>Calories
          </p>
          <span className="text-muted fw-600">
            {nutritionData && nutritionData[nutritionDate]?.calories ? nutritionData[nutritionDate].calories : 0}g
          </span>
        </div>
        <div className="d-flex gap-1 align-items-center justify-content-between">
          <p className="text-dark mb-0 fw-600">
            <span className="grey-dot" style={{ background: '#00AAFF' }}></span>Protein
          </p>
          <span className="text-muted fw-600">
            {nutritionData && nutritionData[nutritionDate]?.protein ? nutritionData[nutritionDate].protein : 0}g
          </span>
        </div>
        <div className="d-flex gap-1 align-items-center justify-content-between">
          <p className="text-dark mb-0 fw-600">
            <span className="grey-dot" style={{ background: '#FFA07A' }}></span>Carbs
          </p>
          <span className="text-muted fw-600">{nutritionData && nutritionData[nutritionDate]?.carbs ? nutritionData[nutritionDate].carbs : 0}g</span>
        </div>
        <div className="d-flex gap-1 align-items-center justify-content-between">
          <p className="text-dark mb-0 fw-600">
            <span className="grey-dot" style={{ background: '#CC66FF' }}></span>Fat
          </p>
          <span className="text-muted fw-600">{nutritionData && nutritionData[nutritionDate]?.fat ? nutritionData[nutritionDate].fat : 0}g</span>
        </div>
        <Button className="btn-block w-100 meal-data-view-nutrition-btn" onClick={props?.showNutritionData}>
          View All Nutrition Data
        </Button>
      </div>
    </div>
  )
}
